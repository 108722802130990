import axios from 'axios';
import { Employee } from './autoevaluationService';

const API_URL = process.env.REACT_APP_API_URL || '';

interface CompanyResponse {
  id: number;
  name: string;
  nit: string;
  phone: string;
  city: string;
  address: string;
  state: string;
  activity_code: string;
  number_of_employees: number;
  user: number;
  risk_level: string;
  arl: string;
  created_at: string;
  modified_at: string;
}

export class CompanyData {
  name: string;
  nit: string;
  phone: string;
  city: string;
  address: string;
  state: string;
  activity_code: string;
  number_of_employees: number;
  risk_level: string;
  arl: string;
  logo_url: string;

  constructor(
    name: string,
    nit: string,
    phone: string,
    city: string,
    address: string,
    state: string,
    activity_code: string,
    number_of_employees: number,
    risk_level: string,
    arl: string,
    logo_url: string,

  ) {
    this.name = name;
    this.nit = nit;
    this.phone = phone;
    this.city = city;
    this.address = address;
    this.state = state;
    this.activity_code = activity_code;
    this.number_of_employees = number_of_employees;
    this.risk_level = risk_level;
    this.arl = arl;
    this.logo_url = logo_url;
  }
}

type OnSuccess = (response: CompanyResponse) => void;

type OnError = (error: any) => void;

const register = (companyData: CompanyData, onSuccess: OnSuccess, onError: OnError): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.post<CompanyResponse>(`${API_URL}/companies/auth/register`, companyData, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};

export interface CompanyDataResponse {
  id?: string;
  name: string;
  nit: string;
  phone: string;
  city: string;
  address: string;
  state: string;
  activity_code: string;
  number_of_employees: number;
  risk_level: string;
  email_user: string;
  arl: string;
  logo_url: string | null;
}

type OnSuccessData = (response: CompanyDataResponse) => void;

type OnErrorData = (error: any) => void;

const getCompanyData = (onSuccess: OnSuccessData, onError: OnErrorData): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<CompanyDataResponse>(`${API_URL}/companies/company`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    })
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};

type OnSuccessOptional = (response?: CompanyDataResponse) => void;

const getMyCompany = (onSuccess: OnSuccessOptional, onError: OnErrorData,): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/me`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    },
  )
    .then(companyResponse => {
      onSuccess(companyResponse.data);
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        onSuccess(undefined);
      } else {
        onError(error);
      }
    });
}

type OnSuccessDownload = (response: Blob) => void;
type OnErrorDownload = (error: any) => void;

const downloadMatrizExcel = (
  year: number,
  onSuccess: OnSuccessDownload,
  onError: OnErrorDownload
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/export/matriz`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year
    },
    responseType: 'blob'
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

const downloadHealthExcel = (
  year: number,
  onSuccess: OnSuccessDownload,
  onError: OnErrorDownload
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/export/health-conditions`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year
    },
    responseType: 'blob'
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

const downloadWorkExcel = (
  year: number,
  onSuccess: OnSuccessDownload,
  onError: OnErrorDownload
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/export/work-conditions`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year
    },
    responseType: 'blob'
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export interface NotificationPlanData {
  id: string;
  item_name?: string;
  customized_item_name?: string;
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  activity?: string;
  manager?: Employee;
  participants?: Employee[];
  start_date?: string;
  end_date?: string;
  state?: string;
  created_at?: string;
  status?: string;
}

export interface NotificationData {
  id: string;
  message: string;
}

interface NotificationResponse {
  plans: NotificationPlanData[]
  notifications: NotificationData[]
}

type OnSuccessNotification = (response: NotificationResponse) => void;

const getNotifications = (
  onSuccess: OnSuccessNotification,
  onError: OnError
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/notifications`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export interface MissingEmployee {
  first_name: string;
  last_name: string;
}

export interface MissingResponse {
  missing_work_conditions_employees?: MissingEmployee[];
  missing_health_conditions_employees?: MissingEmployee[];
  missing_matriz_employees?: MissingEmployee[];
}

type OnSuccessMissing = (response: MissingResponse) => void;


const getMissingEmployees = (
  year: number,
  onSuccess: OnSuccessMissing,
  onError: OnError
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/missing`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year
    }
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export const companyService = {
  register,
  getCompanyData,
  getMyCompany,
  downloadMatrizExcel,
  downloadHealthExcel,
  downloadWorkExcel,
  getNotifications,
  getMissingEmployees
};