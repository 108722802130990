import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CompanyEvidenceData, EvidenceRequest, EvidenceResponse, UploadRequest, uploadImageService } from "../../services/uploadImageServices";
import SuccessModal from "../SuccessModal";
import HeaderSurveys from "../HeaderSurveys";
import { useAppSelector } from "../../app/hooks";
import { mainDivClassName } from "../../utils/MainDivClassName";

interface AddVideosProps {

}

const AddVideos = (props: AddVideosProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const userType = useAppSelector((state) => state.user.userType);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/*': ['.mp4', '.mov', '.wmv', '.avi'] // Acepta formatos de video comunes
    }
  });

  const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {
    return new Promise<EvidenceResponse>((resolve, reject) => {
      uploadImageService.generateUrl(request, (response: EvidenceResponse) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      },
      );
    });
  };

  const uploadAndRegisterVideoPromise = (file: File, url: string, finalUrl: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await uploadImageService.uploadImage(new UploadRequest(file, url),
          response => {
            console.log('Upload success:', response);
            uploadImageService.companyEvidence(new CompanyEvidenceData(finalUrl),
              () => {
                setShowModal(true)
                resolve();
              }, (error) => {
                setErrorMessage("No se pudo subir el video")
                console.error('CompanyEvidence error:', error);
                reject(error);
              });
          }, (error) => {
            console.error('Upload error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error processing file:', error);
        reject(error);
      }
    });
  };

  const handleClick = async (event: React.FormEvent) => {
    setLoading(true);
    setErrorMessage('');

    try {
      for (const file of files) {
        const response = await generateUrlPromise(new EvidenceRequest(file.name));
        console.log('Generated URL:', response);
        await uploadAndRegisterVideoPromise(file, response.url, response.final_url,);
      }
    } catch (err) {
      console.error('Error during file processing:', err);
      setErrorMessage('Error en el envío de Datos de empleados.');
    } finally {
      setLoading(false);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const containerClassName = userType === 'consultant'
    ? 'grid place-items-start justify-items-center h-auto laptop:border laptop:border-white desktop:border desktop:border-white py-5'
    : `grid place-items-start justify-items-center h-screen laptop:border laptop:border-white desktop:border desktop:border-white py-5 ${mainDivClassName}`;


  // { containerClassName } >

  return <>
    {showModal && (
      <SuccessModal
        message="Video enviado con éxito"
        buttonText="Continuar"
        onClose={handleCloseModal}
      />
    )}

    <div className={containerClassName}>
      <div className="grid place-content-between justify-items-center w-[580px] max-h-[700px] overflow-auto  gap-5  bg-white rounded-lg shadow-lg border-2 border-secondaryBrown p-2">
        <HeaderSurveys title={`Evidencia de instalaciones de la empresa`} />
        <div className="grid place-content-between justify-items-center text-center gap-8">
          <div className="grid place-content-center text-center justify-items-center font-semibold p-5 bg-primaryOrange hover:bg-secondaryOrange text-white rounded-md cursor-pointer max-w-[300px] max-h-[36px]" {...getRootProps()}>
            <input className="w-82" {...getInputProps()} />
            {isDragActive ? <p>Suelta el archivo aqui...</p> : <p>Añadir video de la empresa</p>}
          </div>
          <div className="grid  place-content-around place-items-center w-max">
            {files.map((file, index) => (
              <video key={index} controls width="250">
                <source src={URL.createObjectURL(file)} type="video/mp4" />
                Tu navegador no soporta la etiqueta de video.
              </video>
            ))}
          </div>
          {loading && <p className="mt-2">Cargando...</p>}
          {errorMessage && <p className="text-m text-primaryRed">Error: {errorMessage}</p>}
          <div className="min-w-[100px] max-h-[36px] font-semibold border-1 bg-primaryGreen hover:bg-secondaryGreen text-white p-2 rounded-md cursor-pointer">
            <button onClick={handleClick}>Enviar</button>
          </div>
        </div>

      </div>
    </div>
  </>;
}

export default AddVideos;
