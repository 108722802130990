import { useEffect, useState } from 'react'
import { AutoevaluationDataResponse, ImprovementPlansData, autoevaluationService } from '../../services/autoevaluationService';
import { useNavigate } from 'react-router-dom';
import { customizedItems } from '../../utils/customizedItems';
import { EvidenceImprovementPlanDataResponse, GetEvidenceImprovementPlansByEmployee, uploadImageService } from '../../services/uploadImageServices';
import EvidencePlansModal from '../../components/improvementPlan/EvidencePlansModal';
import MyCalendar from '../../components/MyCalendar';
import CircularProgress from '../../components/CircularProgress';
import evidenceIcon from '../../assets/icons/evidence-icon.svg'
import plusIcon from '../../assets/icons/plus-icon.svg'
import commonDiseaseIcon from '../../assets/icons/common-disease.svg'
import occupationalDiseaseIcon from '../../assets/icons/occupational-disease.svg'
import workAccidentIcon from '../../assets/icons/work-accident.svg'
import TableItems, { EvidenceDetails } from '../../components/TableItems';
import TableItemsExtras from '../../components/TableItemsExtras';
import { FaInfoCircle } from 'react-icons/fa';
import MainLayout from '../../components/MainLayout';
import IconTextNumber from '../../components/icon-text-number';
import { formatDateEvents } from '../../components/improvementPlan/ImprovementPlanDetailsModal';
import ModalCalendar from '../../components/Modal';
import { useAppSelector } from '../../app/hooks';
import { AbsenceCountByType, AbsenteeismService } from '../../services/absenteeismService';
import { UploadEvidenceModal } from '../../components/improvementPlan/UploadEvidence';

type Props = {}

const columns = [
  { id: 'item', label: 'Item' },
  { id: 'updatedDate', label: 'Fecha de actualización' },
  { id: 'validity', label: 'Vigencia' },
  { id: 'state', label: 'Estado' },
  { id: 'showEvidence', label: 'Ver evidencia' },
  { id: 'addEvidence', label: 'Añadir evidencia' },
];

const News = [
  'Nueva Ley laboral',
  'Cotización 2025',
  'Cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
];

const monthNamesInSpanish = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

type Row = {
  item: string;
  updatedDate: string;
  validity: string;
  state: string;
  evidences: string;
};

const getItemTextCustom = (itemName?: string): string => {
  const item = customizedItems.find(i => i.propName === itemName);
  return item ? item.text : itemName ?? 'Unknown Item'; // Provide a fallback if itemName is undefined
};

const Home = (props: Props) => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [formData, setFormData] = useState<AutoevaluationDataResponse>();
  const [percentageWork, setPercentageWork] = useState<number>(0)
  const [percentageTraining, setPercentageTraining] = useState<number>(0)
  const [customizedPlans, setCustomizedPlans] = useState<ImprovementPlansData[]>([])
  const [filteredPlans, setFilteredPlans] = useState<Row[]>([]);
  const [plans, setPlans] = useState<ImprovementPlansData[]>([])
  const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [selectedItemPropName, setSelectedItemPropName] = useState<string | undefined>(undefined);
  const [addEvidenceModal, setAddEvidenceModal] = useState<boolean>(false);

  const [evidenceDetails, setEvidenceDetails] = useState<EvidenceDetails[] | null>(null);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [absenceCounts, setAbsenceCounts] = useState<AbsenceCountByType>({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();




  const handleAutoevaluationPdf = () => {
    autoevaluationService.autoevaluationPdf(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'autoevaluation.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  };


  useEffect(() => {
    setErrorMessage("")
    setFormData(undefined)
    autoevaluationService.getAutoevaluation(
      (response) => {
        console.log(response);
        setFormData(response)
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }

    );
    const currentYear = new Date().getFullYear();

    autoevaluationService.getImprovementPlansPercentage(
      currentYear,
      (response) => {
        console.log(response);
        setPercentageWork(response.work_plan__percentage)
        setPercentageTraining(response.training_plan__percentage)
        setCustomizedPlans(response.customized_plans)
        setPlans(response.improvement_plans_list)

      },
      (error) => {
        setErrorMessage(error);
      }
    );

    AbsenteeismService.getCountAbsencesByType(
      (response) => {
        setAbsenceCounts(response)
      },
      (error) => {
        setErrorMessage(error);
      },
      new Date().getMonth() + 1,
      new Date().getFullYear()
    );

  }, [companyId, companyName])



  const handleOpenEvidenceModal = (itemName: string, buttonType: string) => {
    const item = customizedItems.find(i => i.text === itemName);
    setSelectedItem(item?.text);

    if (item && buttonType === 'Ver Ev') {
      uploadImageService.getEvidenceImprovementPlans(
        new GetEvidenceImprovementPlansByEmployee(item.propName),
        (response: EvidenceImprovementPlanDataResponse[]) => {
          setEvidenceDetails(response);
          setEvidenceModalOpen(true);
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
        }
      );
    } else if (item && buttonType === 'Añadir Ev') {
      setSelectedItemPropName(item?.propName)
      setAddEvidenceModal(true)
    }
  };

  const handleCloseEvidenceModal = () => {
    setEvidenceModalOpen(false);
    setSelectedItem(undefined);
    setEvidenceDetails(null);
  };
  const handleCloseCalendarModal = () => {
    setShowCalendar(false)
  };

  const handleCloseAddEvidenceModal = () => {
    setAddEvidenceModal(false)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when rows per page changes
  };

  const getFilteredPlans = (plans: ImprovementPlansData[]): Row[] => {
    return plans.map(plan => {
      const currentYear = new Date().getFullYear();
      const endOfYear = new Date(currentYear, 11, 31); // End of the year

      const createdAtDate = plan.created_at ? new Date(plan.created_at) : new Date();
      const validity = Math.max(0, Math.ceil((endOfYear.getTime() - createdAtDate.getTime()) / (1000 * 60 * 60 * 24)));

      return {
        item: getItemTextCustom(plan.customized_item_name),
        updatedDate: createdAtDate.toLocaleDateString(),
        validity: `${validity} días`,
        state: plan.state || 'No definido', // Proporciona un valor predeterminado
        evidences: plan.customized_item_name || 'No disponible', // Proporciona un valor predeterminado
      };
    });
  };
  useEffect(() => {
    setFilteredPlans(getFilteredPlans(customizedPlans));
  }, [plans, companyId, companyName]);

  return (
    <>
      <MainLayout>
        <div className='flex flex-wrap justify-around items-center gap-6 mt-8'>
          <CircularProgress
            percentage={percentageTraining}
            text='Plan de Capacitación' />
          <CircularProgress
            percentage={percentageWork}
            text='Plan de trabajo' />
          <CircularProgress
            percentage={formData?.total_points ? formData.total_points : 0}
            text='Autoevaluación' />
          <div className='grid place-content-around w-[400px] h-[306px]  border-2 border-secondaryOrange rounded-xl py-4'>
            <div className='text-center text-2xl text-textOrange'>
              Reporte de accidentalidad <strong>{monthNamesInSpanish[new Date().getMonth()]}</strong>
            </div>
            <IconTextNumber
              image={commonDiseaseIcon}
              text="Enfermedad común"
              number={absenceCounts["Enfermedad general"] || 0}
            />
            <IconTextNumber
              image={workAccidentIcon}
              text="Accidentes de trabajo"
              number={absenceCounts["Accidente laboral"] || 0}
            />
            <IconTextNumber
              image={occupationalDiseaseIcon}
              text="Enfermedad laboral"
              number={absenceCounts["Enfermedad profesional"] || 0}
            />
            <span className='text-center text-sm text-primaryRed'>{errorMessage}</span>
          </div>
        </div>
        <div className='flex flex-wrap justify-around items-center gap-6 mt-8'>
          <div className="grid p-4 rounded-lg gap-4 text-center" >
            <div className='text-2xl text-primaryOrange font-semibold'>Autoevaluación</div>
            <button
              onClick={() => navigate('/autoevaluation')}
              className="bg-primaryOrange text-xl rounded-lg w-[372px]  px-4 h-[63.86px] text-black font-semibold hover:bg-secondaryOrange "

            >
              Crear Autoevaluación
            </button>
            <button
              onClick={handleAutoevaluationPdf}
              className="bg-primaryOrange text-xl rounded-lg w-[372px] px-4 h-[63.86px] text-black font-semibold hover:bg-secondaryOrange "
            >
              Descargar autoevaluación
            </button>
          </div>
          <div className="grid  gap-4 text-center  p-4  border-secondaryOrange border-2 h-[282.23px] w-[400px] sm:w-auto rounded-lg">
            <div className="flex justify-center items-center text-center gap-4 row-span-1 bg-secundaryYellow text-primaryBrown font-bold text-xl p-2 rounded-lg">
              Calendario de eventos
              <button
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <img src={plusIcon} alt="" />
              </button>
            </div>
            <div className="row-span-4 scroll-container space-y-4">
              {plans.map((plan, index) => (
                <div key={index} className="bg-gray-200 text-primaryBrown text-lg p-1 rounded-lg">
                  {plan.start_date ? formatDateEvents(plan.start_date) : 'Fecha de inicio no disponible'} - {plan.end_date ? formatDateEvents(plan.end_date) : 'Fecha de fin no disponible'}
                </div>
              ))}
            </div>
          </div>
          <div className="grid  gap-4 text-center p-4  border-secondaryOrange border-2 h-[282.23px] w-[400px] rounded-lg">
            <div className="row-span-1 bg-secundaryYellow text-primaryBrown font-bold text-xl p-2 rounded-lg">
              Noticias Laborus
            </div>
            <div className="row-span-4 scroll-container space-y-4">
              {News.map((newsItem, index) => (
                <div key={index} className="bg-gray-200 text-primaryBrown text-lg p-1 rounded-lg">
                  {newsItem}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-grow px-4 my-8">
          <main className="grid   max-w-full">
            <div className='flex mb-4'>
              <div className="text-xl font-semibold text-secondaryOrange">Items estándares</div>
              <div className="ml-2 relative flex items-center group">
                <FaInfoCircle className="text-secondaryOrange cursor-pointer" />
                <div className="absolute top-full left-0 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-secondaryGray text-black border-2 border-secondaryBrown text-xs rounded p-2 z-50 w-64">
                  Ítems relacionados con estándares legales a cumplir
                </div>
              </div>
            </div>
            <div className='w-full '>
              <TableItems
                formData={formData}
                title="Items estándares"
              />
            </div>
            <div className='w-full my-10'>
              <TableItemsExtras
                title="Items extras"
                columns={columns}
                rows={filteredPlans}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleOpenModal={handleOpenEvidenceModal}
                evidenceIcon={evidenceIcon}
              />
            </div>
            <EvidencePlansModal
              open={evidenceModalOpen}
              onClose={handleCloseEvidenceModal}
              details={evidenceDetails}
              item={selectedItem}
            />
          </main>
        </div>
        <ModalCalendar isOpen={showCalendar} onClose={handleCloseCalendarModal}>
          <MyCalendar plans={plans} />
        </ModalCalendar>
        <UploadEvidenceModal show={addEvidenceModal} onClose={handleCloseAddEvidenceModal} itemText={selectedItem} propName={selectedItemPropName} title={`Del item: ${selectedItem}`} />
      </MainLayout >
    </>
  )
}
export default Home