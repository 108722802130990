import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentAttemptRequest, paymentService } from '../../services/paymentService';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { mainDivClassName } from '../../utils/MainDivClassName';
import HeaderSurveys from '../../components/HeaderSurveys';
import TextFieldString from '../../components/TextFieldString';
import Button from '../../components/Button';
import { plansService } from '../../services/plansService';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const WompiView = () => {
  const [email, setEmail] = useState<string>('');
  const [confirmationEmail, setConfirmationEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  let { planId } = useParams<{ planId?: string }>();
  const [plan, setPlan] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (email === confirmationEmail) {
      setErrorMessage('');
    }
  }, [email, confirmationEmail]);

  useEffect(() => {
    plansService.getPlan(Number(planId),
      (data) => setPlan(data),
      (error) => console.error('Error fetching plan:', error));
  }, []);

  const authenticateEmail = () => {
    if (planId === undefined) {
      return;
    }
    if (email !== confirmationEmail) {
      setErrorMessage("Los correos no coinciden")
      return
    }
    paymentService.createPaymentAttempt(
      new PaymentAttemptRequest(parseInt(planId), email, name, phone),
      response => {
        setShowModal(true);
        setModalMessage('Se ha enviado un enlace al correo para verificarlo y continuar con el pago.');
      },
      error => {
        console.log(error);
        setErrorMessage('Error al enviar la información, ningún campo puede estar vacío.');
      }
    );
  };


  return (
    <>
      <Modal
        open={showModal}
        onClose={() => { }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="text-center" sx={modalStyle}>
          <h2 className='text-xl flex items-center font-rounded font-bold pb-3 ' id="modal-title">Verificación de Correo Electrónico</h2>
          <p id="modal-description">{modalMessage}</p>
        </Box>
      </Modal>

      <div className={`grid h-screen ${mainDivClassName}  p-2`}>
        <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-2 border-secondaryBrown">
          {plan ? (
            <>
              <HeaderSurveys title={`Adquiere el plan ${plan.name}`} />
              <div className="text-4xl font-bold text-secondaryBrown text-center">
                <span className="align-middle text-lg">$</span>
                {plan.price.toLocaleString('es-ES')}
                <span className="text-lg">/mes</span>
              </div>
            </>
          ) : (
            <p>Cargando información del plan...</p>
          )}
          <div className='p-4'>
            <TextFieldString
              label="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disableCut={true}
              disablePaste={true}
            />
            <TextFieldString
              label="Confirma el correo electrónico"
              value={confirmationEmail}
              onChange={(e) => setConfirmationEmail(e.target.value)}
              disableCut={true}
              disablePaste={true}
            />
            <TextFieldString
              label="Nombre de la empresa"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextFieldString
              label="Celular"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <span className='animate-pulse text-center text-red-600 text-sm '>
              {errorMessage && <div>{errorMessage}</div>}
            </span>
            <div className="flex justify-center mt-4 gap-4">
              <button
                onClick={() => { navigate('/plans'); }}
                className='bg-secundaryYellow hover:bg-primaryYellow  text-sm rounded-lg w-auto mobile:min-w-32 px-4 h-8 text-black font-semibold'
              >
                Ver planes
              </button>
              <Button
                text='Hacer pago'
                onClick={authenticateEmail}
              />
            </div>

          </div>


        </div>
      </div>
    </>
  );
}

export default WompiView;
