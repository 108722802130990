import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Employee, PlanDataServiceInterfaceResponse } from '../../services/autoevaluationService';

interface ImprovementPlanDetailsModalProps {
  open: boolean;
  onClose: () => void;
  item: string | undefined;
  details: PlanDataServiceInterfaceResponse | undefined;
}

export const formatDate = (dateString: string | undefined) => {
  if (!dateString) return 'Fecha no disponible';
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('es-ES', options);
};

export const formatDateEvents = (dateString: string | undefined) => {
  if (!dateString) return 'Fecha no disponible';
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('es-ES', options);
};

const ImprovementPlanDetailsModal: React.FC<ImprovementPlanDetailsModalProps> = ({ open, onClose, item, details }) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detalles del Plan de Mejora</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {item ? <strong>Ítem:</strong> : 'No se ha seleccionado ningún ítem.'} {item}
        </DialogContentText>
        {details ? (
          <>
            <DialogContentText>
              <strong>Participantes:</strong>
              {details?.participants?.map((p: Employee) => `${p.first_name} ${p.last_name}`).join(', ')}
            </DialogContentText>
            <DialogContentText>
              <strong>Actividad:</strong> {details.activity}
            </DialogContentText>
            <DialogContentText>
              <strong>Responsable:</strong> {details?.manager ? `${details.manager.first_name} ${details.manager.last_name}` : 'No hay responsable disponible.'}
            </DialogContentText>
            <DialogContentText>
              <strong>Fecha de inicio:</strong> {formatDate(details?.start_date)}
            </DialogContentText>
            <DialogContentText>
              <strong>Fecha Límite:</strong> {formatDate(details?.end_date)}
            </DialogContentText>
            <DialogContentText>
              <strong>Plan de trabajo:</strong> {details.is_work_plan ? 'Sí' : 'No'}
            </DialogContentText>
            <DialogContentText>
              <strong>Plan de capacitación:</strong> {details.is_training_plan ? 'Sí' : 'No'}
            </DialogContentText>
          </>
        ) : (
          <DialogContentText>No hay detalles disponibles.</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImprovementPlanDetailsModal;
