import React from 'react';

export interface Option {
  value: number; // ID de la opción
  label: string; // Texto a mostrar
  disabled?: boolean;
}

interface SelectInputNumberProps {
  id: string;
  label: string;
  value?: number; // El valor seleccionado es un número
  onChange: (value: number) => void; // Cambia el tipo del evento a número
  options: Option[];
  title?: string;
}

const SelectInputNumber: React.FC<SelectInputNumberProps> = ({ id, label, value, onChange, options, title }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = Number(event.target.value); // Convierte el valor a número
    onChange(newValue);
  };

  return (
    <div className="flex flex-col mb-4 w-full">
      <label htmlFor={id} className="mb-1 text-m">
        {label}
      </label>
      <select
        id={id}
        title={title}
        value={value || ''} // Usa el valor como número
        onChange={handleChange}
        className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
      >
        <option value="" disabled>
          Seleccionar...
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value} // Usa el número directamente
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInputNumber;
