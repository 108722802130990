import React from 'react';

interface IconTextNumberProps {
    image: string;
    text: string;
    number: number;
}

const IconTextNumber: React.FC<IconTextNumberProps> = ({ image, text, number }) => {
    return (
        <div className='flex justify-around items-center border-b-[0.5px] border-secondaryBrown gap-6 mx-10 py-2 text-secondaryBrown'>
            <img src={image} alt={text} />
            <p className='text-xl'>{text}</p>
            <span className='text-2xl font-semibold'>{number}</span>
        </div>
    );
};

export default IconTextNumber;
