/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServiceSurveys, SociodemographicMatrizData } from '../../services/employeeSurveysService';
import SuccessModal from '../../components/SuccessModal';
import { mainDivClassName } from '../../utils/MainDivClassName';
import SelectInput from '../../components/SelectInput';
import TextFieldDate from '../../components/TextFieldDate';
import TextFieldNumber from '../../components/TextFieldNumber';
import TextFieldString from '../../components/TextFieldString';
import Button from '../../components/Button';
import HeaderSurveys from '../../components/HeaderSurveys';
import { arls } from './FormCompany';

type Props = {}

const stratus = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" }
];


const epsArray = [
  "ALIANSALUD", "SALUD TOTAL", "SANITAS", "SURA", "FAMISANAR",
  "SERVICIO OCCIDENTAL DE SALUD EPS SOS", "SALUD MIA", "COMFENALCO VALLE",
  "COMPENSAR", "EPM", "FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA",
  "COOSALUD", "NUEVA", "MUTUAL", "CAJACOPI ATLANTICO", "CAPRESOCA",
  "COMFAOCHOCO", "COMFAORIENTE", "EPS FAMILIAR DE COLOMBIA", "ASMET",
  "EMSSANAR", "CAPITAL SALUD", "SAVIA SALUD", "DUSAKAWI",
  "ASOCIACION INDIGENA DEL CAUCA", "ANAS WAYUU", "MALLAMAS",
  "PIJAOS SALUD", "SALUD BOLÍVAR", "NO APLICA"
];


const afps = [
  { value: "COLPENSIONES", label: "COLPENSIONES" },
  { value: "PORVENIR", label: "PORVENIR" },
  { value: "PROTECCIÓN", label: "PROTECCIÓN" },
  { value: "COLFONDOS", label: "COLFONDOS" },
  { value: "OLDMUTUAL", label: "OLD MUTUAL" },
  { value: "NO APLICA", label: "NO APLICA" }
];

const educationLevels = [
  { value: "Primaria", label: "Primaria" },
  { value: "Secundaria", label: "Secundaria" },
  { value: "Técnico", label: "Técnico" },
  { value: "Tecnologo", label: "Tecnólogo" },
  { value: "Profesional", label: "Profesional" },
  { value: "Especialización", label: "Especialización" },
  { value: "Maestria", label: "Maestría" },
  { value: "Doctorado", label: "Doctorado" }
];

const maritalsStatus = [
  { value: "Soltero", label: "Soltero" },
  { value: "Casado", label: "Casado" },
  { value: "Union Libre", label: "Unión Libre" },
  { value: "Viudo", label: "Viudez" },
  { value: "Divorciado", label: "Divorciado" }
];

const homeTypes = [
  { value: "Propia", label: "Propia" },
  { value: "Familiar", label: "Familiar" },
  { value: "Alquilada", label: "Alquilada" }
];

const genders = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Femenino" },
  { value: "ND", label: "No Definido" }
];

const EmploymentTypes = [
  { value: "Termino Fijo", label: "Término Fijo" },
  { value: "Termino Indefinido", label: "Término Indefinido" },
  { value: "Temporal", label: "Temporal" },
  { value: "Prestacion de Servicios", label: "Prestación de Servicios" },
  { value: "Contratista", label: "Contratista" }
];
const FormSociodemographicMatriz = (props: Props) => {
  const [gender, setGender] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date());
  const [employmentType, setEmploymentType] = useState<string>('');
  const [arl, setArl] = useState<string>('');
  const [eps, setEps] = useState<string>('');
  const [afp, setAfp] = useState<string>('');
  const [educationLevel, setEducationLevel] = useState<string>('');
  const [maritalStatus, setMaritalStatus] = useState<string>('');
  const [numberOfSupervisedPeople, setNumberOfSupervisedPeople] = useState<number | null>(null);
  const [hometown, setHometown] = useState<string>('')
  const [socioeconomicStratus, setSocioeconomicStratus] = useState<number>(0);
  const [homeType, setHomeType] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { token } = useParams<{ token?: string }>();

  const handleChangeGender = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGender(event.target.value);
  };

  const handleChangeArl = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setArl(value);
  };

  const handleChangeEps = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setEps(value);
  };

  const handleChangeAfp = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setAfp(value);
  };

  const handleChangeEducationLevel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setEducationLevel(value);
  };

  const handleChangeEmploymentTypes = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setEmploymentType(value);
  };

  const handleChangeMaritalStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setMaritalStatus(value);
  };
  const handleChangeHomeTypes = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setHomeType(value);
  };

  const handleChangeStratus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    setSocioeconomicStratus(value);

  };

  useEffect(() => {

  }, [])

  const handleClick = () => {
    setLoading(true);

    if (numberOfSupervisedPeople === null) {
      setErrorMessage('El número de personas a cargo es requerido');
      setLoading(false);
      return;
    }

    ServiceSurveys.registerSociodemographicMatriz(
      new SociodemographicMatrizData(gender, dateOfBirth, employmentType, arl, eps, afp, educationLevel, maritalStatus, numberOfSupervisedPeople, hometown, socioeconomicStratus, homeType, token
      ),
      response => {
        setLoading(false)
        setShowModal(true)
      },
      err => {
        setErrorMessage('Error en el envio de Datos.');
        setLoading(false)
      },

    );
    setLoading(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const EPSs = epsArray.map(eps => ({
    value: eps,
    label: eps
  }));

  return (
    <>
      {showModal && (
        <SuccessModal
          message="Encuesta enviada con éxito. ¡Gracias por tomarte el tiempo para completar la encuesta!. Ya puedes cerrar la pestaña."
          buttonText=""
          onClose={handleCloseModal}
        />
      )}

      <div className={"flex justify-center h-auto text-secondaryBrown" + (mainDivClassName)} >
        <div className='w-full m-3 h-auto border-2 rounded-md border-secondaryBrown bg-white desktop:w-[700px] desktopL:w-[700px] desktop:my-8 desktopL:my-8 md:my-8'>
          <HeaderSurveys title="Encuesta sociodemográfica" />
          <div className="grid md:grid-cols-2 p-4 gap-2 md:gap-x-32 justify-around  desktop:gap-x-8 desktopL:gap-x-8 "
          >
            <SelectInput
              id="gender-type"
              label="Género"
              value={gender}
              onChange={handleChangeGender}
              options={genders}
            />
            <TextFieldDate
              label="Fecha Nacimiento"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.valueAsDate)}
            />
            <SelectInput
              id="employment-type"
              label="Tipo de vinculación"
              value={employmentType}
              onChange={handleChangeEmploymentTypes}
              options={EmploymentTypes}
            />
            <SelectInput
              id="arl"
              label="ARL"
              value={arl}
              onChange={handleChangeArl}
              options={arls}
            />
            <SelectInput
              id="eps"
              label="EPS"
              value={eps}
              onChange={handleChangeEps}
              options={EPSs}
            />
            <SelectInput
              id="afp"
              label="AFP"
              value={afp}
              onChange={handleChangeAfp}
              options={afps}
            />
            <SelectInput
              id="education-level"
              label="Nivel de educación"
              value={educationLevel}
              onChange={handleChangeEducationLevel}
              options={educationLevels}
            />
            <SelectInput
              id="marital-status"
              label="Estado civil"
              value={maritalStatus}
              onChange={handleChangeMaritalStatus}
              options={maritalsStatus}
            />
            <TextFieldNumber
              label="Número de personas a cargo"
              value={numberOfSupervisedPeople}
              onChange={(e) => setNumberOfSupervisedPeople(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
            />
            <TextFieldString
              label="Ciudad natal"
              value={hometown}
              onChange={(e) => setHometown(e.target.value)}
            />
            <SelectInput
              id="socioeconomic-stratus"
              label="Estrato socioeconomico"
              value={socioeconomicStratus.toString()}
              onChange={handleChangeStratus}
              options={stratus}
            />
            <SelectInput
              id="home-type"
              label="Tipo de vivienda"
              value={homeType}
              onChange={handleChangeHomeTypes}
              options={homeTypes}
            />
            <span className='col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm'>
              {errorMessage && <div>{errorMessage}</div>}
            </span>
          </div>
          <div className="text-center items-center my-3">
            <Button
              disabled={loading}
              onClick={handleClick}
              text='Enviar formulario'
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default FormSociodemographicMatriz
