import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || '';

interface SurveyResponse {
  token: string;
  matrix: any;
}

export class SociodemographicMatrizData {
  gender: string;
  date_of_birth: Date | null;
  employment_type: string;
  eps: string;
  afp: string;
  arl: string;
  education_level: string;
  marital_status: string;
  number_of_supervised_people: number;
  hometown: string;
  socioeconomic_stratus: number;
  home_type: string;
  token: string | undefined;

  constructor(

    gender: string,
    date_of_birth: Date | null,
    employment_type: string,
    eps: string,
    afp: string,
    arl: string,
    education_level: string,
    marital_status: string,
    number_of_supervised_people: number,
    hometown: string,
    socioeconomic_stratus: number,
    home_type: string,
    token: string | undefined,

  ) {
    this.gender = gender;
    this.date_of_birth = date_of_birth;
    this.employment_type = employment_type;
    this.eps = eps;
    this.afp = afp;
    this.arl = arl;
    this.education_level = education_level;
    this.marital_status = marital_status;
    this.number_of_supervised_people = number_of_supervised_people;
    this.hometown = hometown;
    this.socioeconomic_stratus = socioeconomic_stratus;
    this.home_type = home_type;
    this.token = token;
  }
}

type OnSuccess = (response: SurveyResponse) => void;

type OnError = (error: any) => void;

const registerSociodemographicMatriz = (sociodemographicMatrizData: SociodemographicMatrizData, onSuccess: OnSuccess, onError: OnError): void => {
  axios.post<SurveyResponse>(`${API_URL}/companies/employees/sociodemographic_matriz`, (sociodemographicMatrizData),
  )
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};

export class HealthConditionsData {
  equipment_operating: string;
  disease_or_treatment: string;
  symptoms_description: string;
  evolution_time: number | null;
  work_related_symptoms: string;
  symptoms_outside_work: string;
  symptoms_consultation: boolean;
  why_consultation: string;
  incapacity: boolean;
  registration_incapacity: string;
  days_incapacity: number | null;
  hobbies: string;
  time_hobbies: number;
  token: string | undefined;

  constructor(

    equipment_operating: string,
    disease_or_treatment: string,
    symptoms_description: string,
    evolution_time: number | null,
    work_related_symptoms: string,
    symptoms_outside_work: string,
    symptoms_consultation: boolean,
    why_consultation: string,
    incapacity: boolean,
    registration_incapacity: string,
    days_incapacity: number | null,
    hobbies: string,
    time_hobbies: number,
    token: string | undefined,

  ) {
    this.equipment_operating = equipment_operating;
    this.disease_or_treatment = disease_or_treatment;
    this.symptoms_description = symptoms_description;
    this.evolution_time = evolution_time;
    this.work_related_symptoms = work_related_symptoms;
    this.symptoms_outside_work = symptoms_outside_work;
    this.symptoms_consultation = symptoms_consultation;
    this.why_consultation = why_consultation;
    this.incapacity = incapacity;
    this.registration_incapacity = registration_incapacity;
    this.days_incapacity = days_incapacity;
    this.hobbies = hobbies;
    this.time_hobbies = time_hobbies;
    this.token = token;
  }
}

const registerHealthConditions = (healthConditionsData: HealthConditionsData, onSuccess: OnSuccess, onError: OnError): void => {
  axios.post<SurveyResponse>(`${API_URL}/companies/employees/health_conditions`, (healthConditionsData),
  )
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};




export class WorkingConditionsData {

  extreme_temperatures: string | undefined;
  loud_noises: string | undefined;
  excessive_illumination_or_low: string | undefined;
  continuous_vibrations: string | undefined;
  pressure_changes: string | undefined;
  radiation: string | undefined;
  laser_radiation: string | undefined;
  particulate_matter_dust: string | undefined;
  textile_fibers: string | undefined;
  vapors_or_gases: string | undefined;
  spraying_liquids: string | undefined;
  fumes: string | undefined;
  cargo_handling: string | undefined;
  repetitive_motion: string | undefined;
  prolonged_posture: string | undefined;
  vocal_effort: string | undefined;
  electrical_handling: string | undefined;
  mechanic: string | undefined;
  locative: string | undefined;
  technological: string | undefined;
  work_at_height: string | undefined;
  confined_spaces: string | undefined;
  organizational_management: string | undefined;
  organizational_characterization: string | undefined;
  task_conditions: string | undefined;
  group_characteristics: string | undefined;
  person_task_interface: string | undefined;
  working_day: string | undefined;
  safety_helmet: string | undefined;
  safety_glasses: string | undefined;
  hearing_protectors: string | undefined;
  safety_footwear: string | undefined;
  protective_gloves: string | undefined;
  reflective_vest: string | undefined;
  safety_harnesses: string | undefined;
  face_respirators: string | undefined;
  protective_clothing: string | undefined;
  confined_space_protection: string | undefined;
  token: string | undefined;

  constructor(

    extreme_temperatures: string | undefined,
    loud_noises: string | undefined,
    excessive_illumination_or_low: string | undefined,
    continuous_vibrations: string | undefined,
    pressure_changes: string | undefined,
    radiation: string | undefined,
    laser_radiation: string | undefined,
    particulate_matter_dust: string | undefined,
    textile_fibers: string | undefined,
    vapors_or_gases: string | undefined,
    spraying_liquids: string | undefined,
    fumes: string | undefined,
    cargo_handling: string | undefined,
    repetitive_motion: string | undefined,
    prolonged_posture: string | undefined,
    vocal_effort: string | undefined,
    electrical_handling: string | undefined,
    mechanic: string | undefined,
    locative: string | undefined,
    technological: string | undefined,
    work_at_height: string | undefined,
    confined_spaces: string | undefined,
    organizational_management: string | undefined,
    organizational_characterization: string | undefined,
    task_conditions: string | undefined,
    group_characteristics: string | undefined,
    person_task_interface: string | undefined,
    working_day: string | undefined,
    safety_helmet: string | undefined,
    safety_glasses: string | undefined,
    hearing_protectors: string | undefined,
    safety_footwear: string | undefined,
    protective_gloves: string | undefined,
    reflective_vest: string | undefined,
    safety_harnesses: string | undefined,
    face_respirators: string | undefined,
    protective_clothing: string | undefined,
    confined_space_protection: string | undefined,
    token: string | undefined,

  ) {

    this.extreme_temperatures = extreme_temperatures;
    this.loud_noises = loud_noises;
    this.excessive_illumination_or_low = excessive_illumination_or_low;
    this.continuous_vibrations = continuous_vibrations;
    this.pressure_changes = pressure_changes;
    this.radiation = radiation;
    this.laser_radiation = laser_radiation;
    this.particulate_matter_dust = particulate_matter_dust;
    this.textile_fibers = textile_fibers;
    this.vapors_or_gases = vapors_or_gases;
    this.spraying_liquids = spraying_liquids;
    this.fumes = fumes;
    this.cargo_handling = cargo_handling;
    this.repetitive_motion = repetitive_motion;
    this.prolonged_posture = prolonged_posture;
    this.vocal_effort = vocal_effort;
    this.electrical_handling = electrical_handling;
    this.mechanic = mechanic;
    this.locative = locative;
    this.technological = technological;
    this.work_at_height = work_at_height;
    this.confined_spaces = confined_spaces;
    this.organizational_management = organizational_management;
    this.organizational_characterization = organizational_characterization;
    this.task_conditions = task_conditions;
    this.group_characteristics = group_characteristics;
    this.person_task_interface = person_task_interface;
    this.working_day = working_day;
    this.safety_helmet = safety_helmet;
    this.safety_glasses = safety_glasses;
    this.hearing_protectors = hearing_protectors;
    this.safety_footwear = safety_footwear;
    this.protective_gloves = protective_gloves;
    this.reflective_vest = reflective_vest;
    this.safety_harnesses = safety_harnesses;
    this.face_respirators = face_respirators;
    this.protective_clothing = protective_clothing;
    this.confined_space_protection = confined_space_protection;
    this.token = token;
  }
}

const registerWorkConditions = (hData: WorkingConditionsData, onSuccess: OnSuccess, onError: OnError): void => {
  axios.post<SurveyResponse>(`${API_URL}/companies/employees/work_conditions`, (hData),
  )
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};

const resendSurvey = (employeeId: number, survey: string, onSuccess: OnSuccess, onError: OnError): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.post(`${API_URL}/companies/employees/${employeeId}/resend-survey`,
    { survey_name: survey },
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    }
  ).then(response => onSuccess(response.data))
    .catch(error => onError(error))
};

export const ServiceSurveys = {
  registerSociodemographicMatriz,
  registerHealthConditions,
  registerWorkConditions,
  resendSurvey
};
