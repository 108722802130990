import { useState } from 'react';
import { NewUserData, newUserService } from '../../services/newUserService';
import Button from '../../components/Button';
import HeaderSurveys from '../../components/HeaderSurveys';
import TextFieldString from '../../components/TextFieldString';
import { mainDivClassName } from '../../utils/MainDivClassName';
import SuccessModal from '../../components/SuccessModal';
import MainLayout from '../../components/MainLayout';

const RegisterUserAdmin = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);


    const cleanData = () => {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setLoading(false);
        setErrorMessage('');
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    const handleRegistration = () => {
        setErrorMessage('');
        if (confirmPassword === password) {
            setLoading(true);
            newUserService.createNewUser(
                new NewUserData(email, password, 'company'),
                () => {
                    setShowSuccessModal(true);
                    cleanData();
                },
                () => {
                    setLoading(false);
                    setErrorMessage('Error al registrar la contraseña. Por favor, verifica los datos.');
                }
            );
        } else {
            setLoading(false);
            setErrorMessage('Las contraseñas deben ser iguales.');
        }
    };

    return (
        <MainLayout>
            <div className={"mobile:p-2 flex h-screen justify-center items-center text-secondaryBrown " + mainDivClassName}>
                <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-secondaryBrown border-2 relative">
                    <HeaderSurveys title="Registra una empresa" />
                    <div className="flex flex-col justify-center items-center p-4">
                        <TextFieldString
                            label="Correo Electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextFieldString
                            label="Contraseña"
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextFieldString
                            label="Confirma la contraseña"
                            value={confirmPassword}
                            type="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <span className="col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm">
                            {errorMessage && <div>{errorMessage}</div>}
                        </span>
                    </div>
                    <div className="flex justify-center gap-7 pb-4">
                        <Button text="Enviar Registro" onClick={handleRegistration} disabled={loading} />
                    </div>
                </div>
                {showSuccessModal && (
                    <SuccessModal
                        message="Empresa creada con éxito!"
                        buttonText="Continuar"
                        onClose={() => setShowSuccessModal(false)}
                    />
                )}
            </div>
        </MainLayout>

    );
};

export default RegisterUserAdmin;
