import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface ConnectWhatsappResponse {
    qr_code: string;
}

type OnSuccess = (response: ConnectWhatsappResponse) => void;

type OnError = (error: string) => void;

const whatsappQr = (onSuccess: OnSuccess, onError: OnError): void => {
    axios.post<ConnectWhatsappResponse>(`${API_URL}/administrator/whatsapp/qr`, {}, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => {
            const errorMessage = error.response?.data?.error || 'Error desconocido';
            onError(errorMessage);
        });
};

interface CheckStatusResponse {
    estado: string;
}

type OnSuccesCheck = (response: CheckStatusResponse) => void;
const whatsappStatus = (onSuccess: OnSuccesCheck, onError: OnError): void => {
    axios.get<CheckStatusResponse>(`${API_URL}/administrator/whatsapp/status`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => {
            const errorMessage = error.response?.data?.error || 'Error desconocido';
            onError(errorMessage);
        });
};

export interface ConsultantData {
    id: string;
    first_name: string;
    last_name: string;
    id_card: string;
    phone: string;
    email: string;
    type_of_license: string;
    number_of_licence: string;
    place_of_issuance: string;
    date_of_issuance: string;
    url_license_document: string;
    url_course_document: string;
    url_cv: string;
    url_other_diplomas?: string;
    is_consultant_cuidamos: boolean;
    status: string;
}

export interface CompanyAdminData {
    id?: string;
    name: string;
    nit: string;
    phone: string;
    city: string;
    address: string;
    state: string;
    activity_code: string;
    number_of_employees: number;
    consultant: ConsultantData | null;
    risk_level: string;
    arl: string;
    logo_url: string | null;
}

export interface CompaniesDataResponse {
    results: CompanyAdminData[];
    count: number;
    next: string;
    previous: string;
}

type OnSuccessData = (response: CompaniesDataResponse) => void;

type OnErrorData = (error: any) => void;

const companiesData = (onSuccess: OnSuccessData, onError: OnErrorData, url?: string): void => {
    const requestUrl = url || `${API_URL}/administrator/companies`;

    axios.get<CompaniesDataResponse>(requestUrl, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export interface ConsultantDataResponse {
    id: string;
    first_name: string;
    last_name: string;
    id_card: string;
    phone: string;
    user_email: string;
    email: string;
    type_of_license: string;
    number_of_licence: string;
    place_of_issuance: string;
    date_of_issuance: string;
    url_license_document: string;
    url_course_document: string;
    url_cv: string;
    url_other_diplomas?: string;
    is_consultant_cuidamos: boolean;
    status: string;
}

interface ConsultantsDataResponse {
    results: ConsultantDataResponse[];
    count: number;
    next: string;
    previous: string;

}

type OnSuccessConsultantData = (response: ConsultantsDataResponse) => void;

interface ConsultantFilters {
    first_name?: string;
    last_name?: string;
    id_card?: string;
    status?: string;
}

const consultantsData = (
    onSuccess: OnSuccessConsultantData,
    onError: OnErrorData,
    filters?: ConsultantFilters
): void => {
    const params = new URLSearchParams();

    if (filters) {
        if (filters.first_name) params.append('first_name', filters.first_name);
        if (filters.last_name) params.append('last_name', filters.last_name);
        if (filters.id_card) params.append('id_card', filters.id_card);
        if (filters.status) params.append('status', filters.status);
    }

    const url = `${API_URL}/consultants/${params.toString() ? `?${params.toString()}` : ''}`;

    axios.get<ConsultantsDataResponse>(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

const updateCompanyConsultant = (
    consultantId: string,
    companyId: string,
    onSuccess: any,
    onError: OnErrorData
): void => {
    axios.put<CompaniesDataResponse>(
        `${API_URL}/administrator/update/company/${companyId}`,
        {
            consultant_id: consultantId,
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        }
    )
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

const createConsultant = (
    consultantData: ConsultantData,
    onSuccess: (response: ConsultantData) => void,
    onError: OnErrorData
): void => {
    axios.post<ConsultantData>(
        `${API_URL}/consultants/`,
        consultantData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        }
    )
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

const updateConsultant = (
    consultantData: ConsultantData,
    onSuccess: (response: ConsultantData) => void,
    onError: OnErrorData
): void => {
    axios.put<ConsultantData>(
        `${API_URL}/consultants/${consultantData.id}`,
        consultantData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        }
    )
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};



export interface FileConsultantResponse {
    url: string,
    final_url: string
}

export class FileConsultantRequest {
    file_name: string;
    employee_id?: number;

    constructor(file_name: string, employee_id?: number) {
        this.file_name = file_name;
        this.employee_id = employee_id;
    }
}

const generateFileConsultantUrl = (
    request: FileConsultantRequest,
    onSuccess: (response: FileConsultantResponse) => void,
    onError: (error: any) => void,
): void => {
    axios.post<FileConsultantResponse>(`${API_URL}/consultants/upload_url`, request,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};



export const adminService = {
    whatsappQr,
    whatsappStatus,
    companiesData,
    consultantsData,
    createConsultant,
    updateCompanyConsultant,
    generateFileConsultantUrl,
    updateConsultant,
};
