/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServiceSurveys, WorkingConditionsData } from '../../services/employeeSurveysService';
import SuccessModal from '../../components/SuccessModal';
import HeaderSurveys from '../../components/HeaderSurveys';
import { mainDivClassName } from '../../utils/MainDivClassName';
import Button from '../../components/Button';
import SelectInputString from '../../components/SelectInputBoolean';

const options = [
  { value: "Si", label: 'Sí' },
  { value: "No", label: 'No' },
  { value: "No aplica", label: 'No aplica' },
];
const questions = [
  { id: 'extremeTemperatures', label: '¿Trabajas en condiciones de temperaturas altas o bajas. (temperaturas extremas)?' },
  { id: 'loudNoises', label: 'En tu entorno laboral, estás expuesto a ruidos fuertes como martillazos, taladros y maquinaria, que pueden ser intermitentes o continuos.' },
  { id: 'excessiveIlluminationOrLow', label: '¿La iluminación en tu lugar de trabajo es excesiva o insuficiente?' },
  { id: 'continuousVibrations', label: 'Trabajas con equipos, herramientas o maquinaria que producen vibraciones continuas, como taladros, sierras, esmeriladoras y tractores.' },
  { id: 'pressureChanges', label: 'En tu trabajo, sientes cambios de presión, como en las cámaras de refrigeración.' },
  { id: 'radiation', label: 'En tu trabajo, estás expuesto a radiaciones de equipos de rayos X o tomografías.' },
  { id: 'laserRadiation', label: 'En tu trabajo, estás expuesto a radiaciones de equipos láser, soldadura, hornos industriales, microondas y escáneres de seguridad.' },
  { id: 'particulateMatterDust', label: 'Trabajas con material particulado y polvo, como en mecanizado, soldadura, carpintería, panadería, y manejo de fertilizantes o pesticidas.' },
  { id: 'textileFibers', label: 'Fibras textiles y materiales: Estás expuesto a fibras textiles y materiales como fibra de vidrio en la fabricación, carpintería y demolición.' },
  { id: 'vaporsOrGases', label: 'Vapores y gases: Estás expuesto a vapores y gases de solventes, ácidos, cloro, y otros procesos como soldadura y pintura.' },
  { id: 'sprayingLiquids', label: 'Líquidos y rociados: Estás expuesto a líquidos y nieblas de pesticidas, fertilizantes, pintura y limpieza.' },
  { id: 'fumes', label: 'Humos: Estás expuesto a humos durante soldadura, reparación de metales, cocción, y procesos textiles.' },
  { id: 'cargoHandling', label: 'Manipulación manual de cargas: Levantas y mueves objetos pesados sin maquinaria, como cajas en un almacén.' },
  { id: 'repetitiveMotion', label: 'Movimiento repetitivo: Realizas movimientos repetitivos y continuos, como atornillar tuercas.' },
  { id: 'prolongedPosture', label: 'Postura estática: Mantienes posturas estáticas prolongadas, como trabajar frente a una computadora durante muchas horas.' },
  { id: 'vocalEffort', label: 'Esfuerzo vocal: Usas excesivamente las cuerdas vocales en labores donde hablas durante largas horas (consultor, profesor, call center, etc)' },
  { id: 'electricalHandling', label: 'Exposición eléctrica: Trabajas con cables de alta tensión o equipos eléctricos, como reparaciones y mantenimiento.' },
  { id: 'mechanic', label: 'Exposición mecánica: Manejas herramientas y maquinaria, y estás expuesto a materiales proyectados.' },
  { id: 'locative', label: 'Condiciones locativas: Trabajas en almacenes con superficies irregulares y condiciones de orden que pueden causar caídas.' },
  { id: 'technological', label: 'Riesgos tecnológicos: Estás expuesto a riesgos de derrames, fugas, o explosiones debido al uso de maquinaria y tecnología.' },
  { id: 'workAtHeight', label: 'Trabajo en alturas: Te desplazas o trabajas a más de 2 metros de altura.' },
  { id: 'confinedSpaces', label: 'Espacios confinados: Trabajas en áreas cerradas con acceso limitado y ventilación restringida, como tanques o alcantarillas.' },
  { id: 'organizationalManagement', label: 'Gestión organizacional: Experimentas condiciones relacionadas con el estilo de mando, pago, capacitación y bienestar en el trabajo.' },
  { id: 'organizationalCharacterization', label: 'Organización del trabajo: La comunicación, tecnología y organización afectan la eficiencia y seguridad en el trabajo.' },
  { id: 'taskConditions', label: 'Condiciones de la tarea: Enfrentas cargas mentales y emocionales, demandadas por la tarea y sistemas de control.' },
  { id: 'groupCharacteristics', label: 'Características del grupo: Las dinámicas y relaciones en el equipo afectan el ambiente laboral y la productividad.' },
  { id: 'personTaskInterface', label: 'Interfase persona-tarea: La falta de capacitación para tareas avanzadas puede aumentar el riesgo de errores o lesiones.' },
  { id: 'workingDay', label: 'Jornada de trabajo: Tu jornada incluye pausas, trabajo nocturno, rotación de turnos y horas extras.' },
  { id: 'safetyHelmet', label: 'Casco de seguridad: Para proteger la cabeza contra impactos y objetos que caen.' },
  { id: 'safetyGlasses', label: 'Gafas de seguridad: Para proteger los ojos contra partículas voladoras, líquidos corrosivos o radiación.' },
  { id: 'hearingProtectors', label: 'Tapones o protectores auditivos: Para proteger los oídos contra el ruido excesivo.' },
  { id: 'safetyFootwear', label: 'Calzado de seguridad: Con puntera de acero o composite para proteger los pies contra impactos y objetos pesados.' },
  { id: 'protectiveGloves', label: 'Guantes de protección: Para proteger las manos contra cortes, abrasiones, químicos u otros riesgos específicos del trabajo.' },
  { id: 'reflectiveVest', label: 'Chaleco reflectante o de alta visibilidad: Para trabajos en áreas de tráfico vehicular o en condiciones de poca luz.' },
  { id: 'safetyHarnesses', label: 'Arneses de seguridad y líneas de vida: Para trabajos en altura y prevención de caídas.' },
  { id: 'faceRespirators', label: 'Respiradores o máscaras faciales: Para proteger contra inhalación de polvo, vapores, gases o contaminantes biológicos.' },
  { id: 'protectiveClothing', label: 'Ropa de protección: Como trajes ignífugos, trajes de lluvia o uniformes de alta visibilidad, según el entorno de trabajo y los riesgos presentes.' },
  { id: 'confinedSpaceProtection', label: 'Equipo de protección para trabajo en espacios confinados: Como detectores de gas, arneses y sistemas de rescate, según sea necesario.' },
];


const FormWorkConditions = () => {

  const [formData, setFormData] = useState<{ [key: string]: string | undefined }>({});
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { token } = useParams<{ token?: string }>();


  const handleSelectChange = (id: string, value: string) => {
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };


  const handleClick = () => {
    setLoading(true);
    if (formData === undefined) return

    ServiceSurveys.registerWorkConditions(
      new WorkingConditionsData(formData.extremeTemperatures, formData.loudNoises, formData.excessiveIlluminationOrLow, formData.continuousVibrations, formData.pressureChanges, formData.radiation, formData.laserRadiation, formData.particulateMatterDust, formData.textileFibers, formData.vaporsOrGases, formData.sprayingLiquids, formData.fumes, formData.cargoHandling, formData.repetitiveMotion, formData.prolongedPosture, formData.vocalEffort, formData.electricalHandling, formData.mechanic, formData.locative, formData.technological, formData.workAtHeight, formData.confinedSpaces, formData.organizationalManagement, formData.organizationalCharacterization, formData.taskConditions, formData.groupCharacteristics, formData.personTaskInterface, formData.workingDay, formData.safetyHelmet, formData.safetyGlasses, formData.hearingProtectors, formData.safetyFootwear, formData.protectiveGloves, formData.reflectiveVest, formData.safetyHarnesses, formData.faceRespirators, formData.protectiveClothing, formData.confinedSpaceProtection, token
      ),
      response => {
        setLoading(false)
        setShowModal(true)
      },
      err => {
        setErrorMessage('Error en el envio de Datos.');
        setLoading(false)
      },

    );
    setLoading(false);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <SuccessModal
          message="Encuesta enviada con éxito. ¡Gracias por tomarte el tiempo para completar la encuesta!. Ya puedes cerrar la pestaña."
          buttonText=""
          onClose={handleCloseModal}
        />
      )}

      <div className={"flex justify-center h-auto text-secondaryBrown " + (mainDivClassName)} >
        <div className='w-full m-3 h-auto border-2 rounded-md border-secondaryBrown bg-white  desktop:w-3/6 desktopL:w-3/6 desktop:my-8 desktopL:my-8 md:my-8'>
          <HeaderSurveys title="Condiciones de trabajo" />
          <div className="grid md:grid-cols-2 p-4 gap-2 md:gap-x-32 place-items-end desktop:gap-x-8 desktopL:gap-x-8 "
          >
            {questions.map((question) => (
              <div key={question.id} className="mb-4">
                <SelectInputString
                  key={question.id}
                  id={question.id}
                  label={question.label}
                  value={formData[question.id]}
                  onChange={(value) => handleSelectChange(question.id, value)}
                  options={options}
                />
              </div>

            ))}

            <span className='col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm'>
              {errorMessage && <div>{errorMessage}</div>}
            </span>
          </div>
          <div className="text-center items-center my-3">
            <Button
              disabled={loading}
              onClick={handleClick}
              text='Enviar formulario'
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default FormWorkConditions
