import { useAppSelector } from "../../app/hooks";
import MainLayout from "../../components/MainLayout";
import { TabsCustomAnimation } from "./FinalRegistration";

export function FinalRegistrationContainer() {
    const userState = useAppSelector((state) => state.user);

    const isConsultor = userState.userType === 'consultant'; // Ajusta según tu lógica de roles
    // Se envuelve el componente con MainLayout cuando es consultor para mantener
    // la navegación consistente con el sidebar y estructura principal del sitio
    if (isConsultor) {
        return (
            <MainLayout>
                <TabsCustomAnimation />
            </MainLayout>
        );
    }

    // Si es company u otro tipo de usuario
    return <TabsCustomAnimation />;
}