import { useEffect, useState } from 'react';
import { Box, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Button from '../../components/Button';
import MainLayout from '../../components/MainLayout';
import { DataWorkEnv, uploadImageService } from '../../services/uploadImageServices';
import CreateEmployeeModal from '../../components/createEmployee/CreateEmployeeModal';
import { EmployeesBatchData, EmployeesListData, employeesService } from '../../services/employeesService';
import AddImages from '../../components/uploadImagesAndVideo/AddImages';
import EvidenceModal from '../../components/evidenceModal';
import { autoevaluationService, ImprovementPlansData, GetImprovementPlansByEmployeeParams } from '../../services/autoevaluationService';
import ImprovementPlansPerEmployeeModal from '../../components/ImprovementPlansPerEmployee';
import { companyService, MissingEmployee, MissingResponse } from '../../services/companyServices';
import evidenceIcon from '../../assets/icons/evidence-icon.svg'
import { ServiceSurveys } from '../../services/employeeSurveysService';
import CancelButton from '../../components/CancelButton';
import HeaderSurveys from '../../components/HeaderSurveys';
import AddExcel from '../../components/AddExcel';
import { useAppSelector } from '../../app/hooks';

const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'id_card', label: 'Cédula' },
  { id: 'phone', label: 'Celular' },
  { id: 'email', label: 'Correo' },
  { id: 'role', label: 'Rol' },
  { id: 'status', label: 'Estado' },
  { id: 'actions', label: 'Cambiar Estado' },
  { id: 'resend', label: 'Reenviar Encuesta' },
  { id: 'evidence', label: 'Evidencia' },
  { id: 'edit', label: 'Editar' },
];
const ModuleEmployees = () => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [showModal, setShowModal] = useState(false);
  const [employeesList, setEmployeesList] = useState<EmployeesListData[]>([]);
  const [employee, setEmployee] = useState<EmployeesListData>();
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [showCreateEvidenceModal, setShowCreateEvidenceModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);
  const [next, setNext] = useState<string | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [previous, setPrevious] = useState<string | undefined>(undefined);
  const [workEnv, setWorkEnv] = useState<DataWorkEnv[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [evidenceType, setEvidenceType] = useState<string>('');
  const [improvementPlansPerEmployee, setImprovementPlansPerEmployee] = useState<ImprovementPlansData[]>([]);
  const [showImprovementPlansModal, setShowImprovementPlansModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState<boolean>(false);
  const [idCard, setIdCard] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [showJobsModal, setShowJobsModal] = useState(false);
  const [showAddEvidenceModal, setShowAddEvidenceModal] = useState(false);
  const [showImportEmployeesModal, setShowImportEmployeesModal] = useState(false);
  const [errorMessagePlans, setErrorMessagePlans] = useState<string>();
  const [errorMessageSurvey, setErrorMessageSurvey] = useState<string>();
  const [missingData, setMissingData] = useState<MissingResponse>()
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditEmployee = (employee: EmployeesListData) => {
    setEmployee(employee);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEmployee(undefined);
  };

  const handleImportModal = () => {
    setShowImportEmployeesModal(true)
  }

  const handleCloseImportEmployeesModal = () => {
    setShowImportEmployeesModal(false);
    setEmployee(undefined);
    fetchEmployees()
  };

  const handleEmployeeUpdate = (formData: any) => {
    employeesService.updateEmployee(
      formData.id,
      formData,
      () => {
        setShowEditModal(false);
        setSuccessMessage("Empleado actualizado correctamente ✅");
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, 1500);
        fetchEmployees();
        setEmployee(undefined);
      },
      err => {
        console.log(err);
        setErrorMessage("Error al actualizar el empleado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      }
    );
  };


  const fetchEmployees = (url?: string) => {
    employeesService.employeesData(
      (response) => {
        setEmployeesList(response.results);
        setNext(response.next);
        setPrevious(response.previous);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      url,
      idCard || undefined,
      10,
      undefined,
      firstName || undefined,
      lastName || undefined

    );
  };

  const handleButtonFilter = () => {
    fetchEmployees()
  }

  const handleDownload = (response: BlobPart, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadMatriz = () => {
    companyService.downloadMatrizExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'Matriz.xlsx');
      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de la matriz en el año ${selectedYear}`)
      }
    );
  };
  const handleDownloadHealthConditions = () => {
    companyService.downloadHealthExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'CondicionesDeSalud.xlsx');
      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de condiciones de salud en el año ${selectedYear}`)
      }
    );
  };

  const handleDownloadWorkConditions = () => {
    companyService.downloadWorkExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'CondicionesDeTrabajo.xlsx');

      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de condiciones de trabajo en el año ${selectedYear}`)
      }
    );
  };

  const handleExportOptionClick = (type: string) => {
    switch (type) {
      case 'matrix':
        handleDownloadMatriz();
        break;
      case 'healthConditions':
        handleDownloadHealthConditions();
        break;
      case 'workConditions':
        handleDownloadWorkConditions();
        break;
      default:
        console.error('Unknown export type');
    }
  };
  const handleOptionSuvey = (type: string) => {
    setErrorMessage('');
    setSuccessMessage('');
    ServiceSurveys.resendSurvey(
      employee!.id, type,
      () => setSuccessMessage('La encuesta se ha reenviado correctamente.'),
      () => setErrorMessage('Error al reenviar la encuesta.'),
    );
  };

  useEffect(() => {
    fetchEmployees();
  }, [companyId, companyName]);

  const handleCreateEmployee = () => {
    setShowModal(true);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStatusChange = (employee: EmployeesListData) => {
    const updatedStatus = employee.status === 'Activo' ? 'Inactivo' : 'Activo';

    const empleadoActualizado = {
      first_name: employee.first_name,
      last_name: employee.last_name,
      id_card: employee.id_card,
      phone: employee.phone,
      email: employee.email,
      role: employee.role,
      status: updatedStatus,
    };
    employeesService.updateEmployee(
      employee.id,
      empleadoActualizado,
      response => {
        setSuccessMessage("Empleado actualizado ✅");
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, 1500);
        fetchEmployees();
      },
      err => {
        console.log(err);
        setErrorMessage("Error al actualizar el estado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    );
    setSuccessMessage(" ");
  };

  const handleEmployeeCreate = (formData: any) => {
    employeesService.registerEmployee(
      new EmployeesBatchData([formData]),
      () => {
        setShowModal(false);
        fetchEmployees();
        setSuccessMessage("Empleado creado correctamente ✅");
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, 1500);
      },
      error => {

        let errorMessage = error.message;

        if (error.conflicts) {
          const conflictMessages = error.conflicts
            .map((conflict: { field: string; value: string }) => `${conflict.field}: ${conflict.value}`)
            .join(", ");
          errorMessage += ` - ${conflictMessages}`;
        }

        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    );
  };

  const handleEvidenceClick = (employeeId: number) => {
    setSelectedEmployeeId(employeeId);
    setEvidenceType('');
    setShowCreateEvidenceModal(true);
  };
  const handleSurveyClick = (employee: EmployeesListData) => {
    setEmployee(employee);
    setShowSurveyModal(true);
  };
  const handleShowEvidenceWork = () => {
    uploadImageService.getworkEvidence(
      selectedEmployeeId!,
      (response) => {
        console.log('Datos recibidos:', response);
        setWorkEnv(response.results)
        setShowEvidenceModal(true);
      },
      (error) => {
        console.log('Error al obtener los datos:', error);
        setErrorMessage("No tienes evidencias");
      }
    );

  };
  const handleAddEvidenceModal = () => {
    setShowAddEvidenceModal(true)
  }

  const handleEvidenceTypeClick = (type: string) => {
    setEvidenceType(type);

    if (type === 'work') {
      setShowJobsModal(true)

    } else if (type === 'improvement') {
      const currentYear = new Date().getFullYear();

      autoevaluationService.getImprovementPlansPerEmployee(
        new GetImprovementPlansByEmployeeParams(currentYear, selectedEmployeeId!),
        (response) => {
          console.log("Planes de mejora por employee: ", response.improvement_plans);
          setImprovementPlansPerEmployee(response.improvement_plans);
          if (response.improvement_plans.length > 0) {
            setShowImprovementPlansModal(true);
          }
          setShowCreateEvidenceModal(false);

        },
        (error) => {
          console.error('Error al obtener los datos:', error);
          setErrorMessagePlans('No tiene planes de mejora')
          setShowCreateEvidenceModal(true);

        },
      );
    }
  };

  const handleCloseEvidenceModal = () => {
    setShowEvidenceModal(false);
    setShowCreateEvidenceModal(false);
    setEvidenceType('');
    setErrorMessagePlans('')
  };
  const handleCloseJobsModal = () => {
    setShowEvidenceModal(false);
    setShowCreateEvidenceModal(false);
    setSelectedEmployeeId(undefined);
    setEvidenceType('');
    setErrorMessage('')
    setShowJobsModal(false)
  };
  const handleCloseAddEvidenceModal = () => {
    setShowEvidenceModal(false);
    setShowCreateEvidenceModal(false);
    setEvidenceType('');
    setErrorMessage('')
    setShowAddEvidenceModal(false)
  };

  const handleCloseImprovementPlansModal = () => {
    setShowImprovementPlansModal(false);
  };

  const getMissingEmployee = (year: number) => {
    companyService.getMissingEmployees(
      year,
      response => {
        setMissingData(response)
      },
      err => {
        console.log(err);
        setErrorMessage("Error al actualizar el estado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    )
  };
  useEffect(() => {
    getMissingEmployee(selectedYear)
  }, [selectedYear, companyId, companyName])


  const handleOpenExportModal = () => {
    getMissingEmployee(selectedYear)
    setShowExportModal(true);
  };

  const handleCloseExportModal = () => {
    setShowExportModal(false);
    setErrorMessageSurvey("")
  };
  const handleCloseSurveyModal = () => {
    setShowSurveyModal(false)
    setErrorMessage("")
    setSuccessMessage("")
  };
  const renderMissingEmployees = (employees?: MissingEmployee[]) => {
    if (!employees || employees.length === 0) {
      return null;
    }

    return (
      <ul className=' list-disc pl-5 text-secondaryBrown '>
        {employees.map((employee, index) => (
          <li key={index}>{employee.first_name} {employee.last_name}</li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <MainLayout>
        <div className='text-center text-secondaryBrown '>
          <h1 className="text-4xl text-start font-bold mb-16 text-primaryOrange">Empleados</h1>
          <div className='flex justify-between items-end'>
            <div className='flex  gap-9'>
              <Button
                onClick={handleCreateEmployee}
                text='Añadir empleado'
              />
              <Button
                onClick={handleImportModal}
                text='Importar empleados'
              />
              <Button
                onClick={handleOpenExportModal}
                text='Exportar encuesta'
              />
            </div>
            <div className='flex items-end  gap-9'>
              <div className="flex flex-col w-full">
                <label className="mb-1 text-m text-start">Nombre</label>
                <input
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="mb-1 text-m text-start">Apellido</label>
                <input
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="mb-1 text-m text-start">Cédula</label>
                <input
                  type='number'
                  value={idCard}
                  onChange={(e) => setIdCard(e.target.value)}
                  className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                />
              </div>
              <Button
                onClick={handleButtonFilter}
                text='Filtrar'
              />
            </div>
          </div>
          <div className='text-green-500 text-sm font-normal py-3'>
            {successMessage}
          </div>
          <div className='text-red-500 text-sm font-normal py-3'>
            {errorMessage}
          </div>
          <div className="text-sm overflow-x-auto  w-full max-h-[600px]">
            <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
              <thead className="sticky top-0 bg-white ">
                <tr>
                  {columns.map((column) => (
                    <th key={column.id} className="text-center z-10  px-2 py-4 text-m font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg">
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {employeesList.length > 0 ? (
                  employeesList.map((employee) => (
                    <tr key={employee.id} className="hover:bg-gray-100">
                      <td className="px-2 py-4 whitespace-nowrap ">
                        {employee.first_name} {employee.last_name}
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.id_card}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.phone}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.email}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.role_es}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.status}</td>
                      <td className="px-1 py-4 whitespace-nowrap">
                        <button
                          onClick={() => handleStatusChange(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                        >
                          {employee.status === 'Activo' ? 'Desactivar' : 'Activar'}
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center">
                        <button
                          onClick={() => handleSurveyClick(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                          disabled={!(employee.status === 'Activo')}
                        >
                          Encuesta
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center">
                        <button onClick={() => handleEvidenceClick(employee.id)}>
                          <img src={evidenceIcon} alt="Logo evidencias" />
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center"> {/* Nueva columna */}
                        <button
                          onClick={() => handleEditEmployee(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                        >
                          Editar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-4 text-center text-gray-500">
                      No hay Empleados.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between gap-5 mt-4">
            <button
              className="p-2 border border-gray-300 rounded bg-gray-200"
              color="primary"
              disabled={!previous}
              onClick={() => fetchEmployees(previous)}
            >
              Anterior
            </button>
            <button
              className="p-2 border border-gray-300 rounded bg-gray-200"
              color="primary"
              disabled={!next}
              onClick={() => fetchEmployees(next)}
            >
              Siguiente
            </button>
          </div>
          <br />
        </div>
      </MainLayout>

      <CreateEmployeeModal title='Crear' open={showModal} onClose={handleCloseModal} onCreateOrUpdate={handleEmployeeCreate} />

      {evidenceType !== 'work' && (

        <Modal open={showCreateEvidenceModal} onClose={handleCloseEvidenceModal}>
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '500px',
              maxHeight: '700px',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: '16px',
              overflowY: 'auto',
            }}
          >
            <div>
              <HeaderSurveys
                title="Elija el tipo de evidencia"
                description="Documento que demuestra el cumplimiento de requisitos legales, como actas, certificados o reportes. Necesita subir el archivo y asociarlo al proceso correspondiente. La aplicación organiza automáticamente las evidencias para facilitar auditorías y consultas, manteniendo todo en un solo lugar de forma accesible y ordenada."
              />
              <div className='flex justify-around gap-4'>
                <Button
                  onClick={() => handleEvidenceTypeClick('work')}
                  text='Puestos de trabajo'
                />
                <span></span>
                <div className='grid'>
                  <Button
                    onClick={() => handleEvidenceTypeClick('improvement')}
                    text='Planes de mejora'
                  />

                  <span className='text-sm text-red-500'>{errorMessagePlans}</span>
                </div>
              </div>
              <div className='flex justify-center text-center gap-4 mt-10'>
                <CancelButton
                  onClick={handleCloseEvidenceModal}
                  text='Cerrar'
                />
              </div>
            </div>
          </Box>
        </Modal>
      )}


      <Modal open={showJobsModal} onClose={handleCloseJobsModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '700px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '16px',
            overflowY: 'auto',
          }}
        >
          <>
            <div>
              <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
                <div className="flex justify-center items-center p-4">
                  <div className='grid'>
                    <h1 className="mobile:text-2xl text-4xl   font-rounded font-semibold text-primaryOrange">
                      Evidencias de puestos de trabajo
                    </h1>
                  </div>
                </div>
              </div>
              <div className='flex justify-around gap-4'>
                <Button
                  onClick={handleShowEvidenceWork}
                  text='Ver evidencia'
                />
                <Button
                  onClick={handleAddEvidenceModal}
                  text='Añadir evidencia'
                />
              </div>
            </div>
            <div className='flex justify-center items-center text-center mt-4'>
              <span className='text-sm text-red-500'>{errorMessage}</span>
            </div>
            <div className='flex justify-center text-center gap-4 mt-6'>
              <CancelButton
                onClick={handleCloseJobsModal}
                text='Cerrar'
              />
            </div>
          </>
        </Box>
      </Modal>
      <Modal open={showAddEvidenceModal} onClose={handleCloseAddEvidenceModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '800px',
            minHeight: '350px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '16px',
            overflowY: 'auto',
          }}
        >
          {selectedEmployeeId && (
            <>
              <div className='grid gap8'>
                <HeaderSurveys title=' Añade el puesto de trabajo' />
                <AddImages employeeId={selectedEmployeeId} />
              </div>

              <div className='flex justify-center text-center gap-4 mt-6'>
                <CancelButton
                  onClick={handleCloseAddEvidenceModal}
                  text='Cerrar'
                />
              </div>
            </>
          )
          }
        </Box>
      </Modal>

      <EvidenceModal open={showEvidenceModal} onClose={handleCloseEvidenceModal} evidence={workEnv} />
      <ImprovementPlansPerEmployeeModal open={showImprovementPlansModal} onClose={handleCloseImprovementPlansModal} improvementPlans={improvementPlansPerEmployee} employeeId={selectedEmployeeId} />

      <Modal open={showExportModal} onClose={handleCloseExportModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '800px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '16px',
            overflowY: 'auto',
          }}
        >
          <div className='grid place-content-between justify-between gap-4 h-full'>
            <HeaderSurveys title="Elija la encuesta que va a exportar y el año" />
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Seleccionar Año</InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value as number)}
                label="Seleccionar Año"
                className='h-[36px] text-secondaryGray'
              >
                {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className='grid place-content-center  gap-4 h items-center'>
              <button
                onClick={() => handleExportOptionClick('matrix')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Exportar Matriz
              </button>
              {missingData?.missing_matriz_employees && missingData.missing_matriz_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-start'>
                    Faltan {missingData.missing_matriz_employees.length} empleados por realizar esta encuesta
                  </span>
                  {renderMissingEmployees(missingData.missing_matriz_employees)}
                </>
              )}
              <button
                onClick={() => handleExportOptionClick('healthConditions')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Exportar Condiciones de Salud
              </button>
              {missingData?.missing_health_conditions_employees && missingData.missing_health_conditions_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-center'>
                    Faltan {missingData.missing_health_conditions_employees.length} empleados por realizar esta encuesta.
                  </span>
                  {renderMissingEmployees(missingData.missing_health_conditions_employees)}
                </>
              )}
              <button
                onClick={() => handleExportOptionClick('workConditions')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Exportar Condiciones de trabajo
              </button>

              {missingData?.missing_work_conditions_employees && missingData.missing_work_conditions_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-center'>
                    Faltan {missingData.missing_work_conditions_employees?.length} empleados por realizar esta encuesta.
                  </span>
                  {renderMissingEmployees(missingData.missing_work_conditions_employees)}
                </>
              )}
              <span className='text-lg text-red-500 mt-3 text-center'>{errorMessageSurvey}</span>
            </div>
            <div className='flex justify-center text-center gap-4 mt-4'>
              <CancelButton
                onClick={handleCloseExportModal}
                text='Cerrar'
              />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={showSurveyModal} onClose={handleCloseSurveyModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '700px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '16px',
            overflowY: 'auto',
          }}
        >
          <div className='grid justify-items-center place-content-between justify-between items-center gap-4 '>
            <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
              <div className="flex justify-center items-center p-4">
                <div className='grid'>
                  <h1 className="mobile:text-2xl text-4xl   font-rounded font-semibold text-primaryOrange">
                    Reenvío de encuestas
                  </h1>
                  <div className="flex text-xl justify-center text-secondaryBrown mt-2">
                    <span>Seleccione la encuesta a reenviar</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-8 items-center justify-center '>
              <button
                onClick={() => handleOptionSuvey('matriz_sociodemographic')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Matriz sociodemográfica
              </button>
              <button
                onClick={() => handleOptionSuvey('health_conditions')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Condiciones de salud
              </button>
              <button
                onClick={() => handleOptionSuvey('work_conditions')}
                className='w-[300px] h-[36px] bg-primaryOrange text-white rounded-lg text-m font-semibold'
              >
                Condiciones de trabajo
              </button>
              <span className='text-lg text-red-500 mt-3 text-center'>{errorMessage}</span>
              <span className='text-lg text-primaryBrown mt-3 text-center'>{successMessage}</span>
            </div>
            <div className='flex justify-end text-center gap-4 '>
              <CancelButton
                onClick={handleCloseSurveyModal}
                text='Cerrar'
              />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={showImportEmployeesModal} onClose={handleCloseImportEmployeesModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '800px',
            minHeight: '400px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '16px',
            overflowY: 'auto',
          }}
        >
          <>
            <div className='grid gap8'>
              <HeaderSurveys title=' Añade el puesto de trabajo' />
              <AddExcel />
            </div>

            <div className='flex justify-center text-center gap-4 mt-6'>
              <CancelButton
                onClick={handleCloseImportEmployeesModal}
                text='Cerrar'
              />
              <Button
                onClick={() => { window.location.href = `${process.env.PUBLIC_URL}/import/importEmployeesTemplate.xlsx`; }}
                text='Descargar plantilla'

              />

            </div>
          </>
        </Box>
      </Modal>
      <CreateEmployeeModal
        open={showEditModal}
        title='Editar'
        onClose={handleCloseEditModal}
        onCreateOrUpdate={handleEmployeeUpdate}
        employee={employee}
      />

    </>
  );
};

export default ModuleEmployees;
