import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export interface CompanyDataResponse {
    id?: string;
    name: string;
    nit: string;
    phone: string;
    city: string;
    address: string;
    state: string;
    activity_code: string;
    number_of_employees: number;
    risk_level: string;
    email_user: string;
    arl: string;
    logo_url: string | null;
}

interface CompaniesResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: CompanyDataResponse[];
}

type OnSuccessData = (response: CompaniesResponse) => void;

type OnErrorData = (error: any) => void;

const getCompaniesData = (onSuccess: OnSuccessData, onError: OnErrorData): void => {
    axios.get<CompaniesResponse>(`${API_URL}/companies/consultant/companies`,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // Extrae la información de la respuesta y pasa a onSuccess
            const { data } = response;
            onSuccess(data);  // data incluye count, next, previous, results (array)
        })
        .catch(error => onError(error));
};

export const consultantService = {
    getCompaniesData
};
