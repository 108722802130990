import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export interface AbsenceResponse {
  id: number;
  absense_type: string;
  description: string | null;
  start_date: string;
  end_date: string;
  evidence_url: string;
  employee_data: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export class AbsenceData {
  absense_type: string;
  description: string | null;
  start_date: string;
  end_date: string;
  evidence_url: string;
  employee: number;

  constructor(
    absense_type: string,
    description: string | null,
    start_date: string,
    end_date: string,
    evidence_url: string,
    employee: number
  ) {
    this.absense_type = absense_type;
    this.description = description;
    this.start_date = start_date;
    this.end_date = end_date;
    this.evidence_url = evidence_url;
    this.employee = employee;
  }
}

type OnSuccess = (response: AbsenceResponse) => void;
type OnError = (error: any) => void;

const createAbsence = (absenceData: AbsenceData, onSuccess: OnSuccess, onError: OnError): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.post<AbsenceResponse>(`${API_URL}/absenteeism/absenses`, absenceData, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

// Nueva función para actualizar la ausencia
const updateAbsence = (absenceData: AbsenceResponse, onSuccess: OnSuccess, onError: OnError): void => {
  // Crear un nuevo objeto para la actualización
  const updatedData = {
    ...absenceData,
    employee: absenceData.employee_data.id, // Convertir employee_data a employee
    employee_data: undefined, // Eliminar employee_data
  };

  const companyId = localStorage.getItem('company_id') ?? '';
  axios.put<AbsenceResponse>(`${API_URL}/absenteeism/absenses/${absenceData.id}`, updatedData, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

const deleteAbsence = (id: number, onSuccess: () => void, onError: OnError): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.delete(`${API_URL}/absenteeism/absenses/${id}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(() => onSuccess())
    .catch(error => onError(error));
};

type OnSuccessGet = (response: AbsenceResponse[]) => void;

export const getAbsences = (onSuccess: OnSuccessGet, onError: OnError, from: Date | null, to: Date | null, absense_type?: string | null): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<AbsenceResponse[]>(`${API_URL}/absenteeism/absenses`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      from: from,
      to: to,
      absense_type: absense_type,
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};


export interface AbsenceCountByType {
  [key: string]: number;
}

type OnSuccessByType = (response: AbsenceCountByType) => void;

export const getCountAbsencesByType = (onSuccess: OnSuccessByType, onError: OnError, month: number | null, year: number | null): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<AbsenceCountByType>(`${API_URL}/absenteeism/count-by-type`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      month: month,
      year: year
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => {
      if (error.response && error.response.data) {
        onError(error.response.data.error || 'Error desconocido');
      } else {
        onError('Error de red o inesperado');
      }
    });
};

export const AbsenteeismService = {
  createAbsence,
  updateAbsence,
  getAbsences,
  deleteAbsence,
  getCountAbsencesByType
};
