import { useState, useImperativeHandle, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImageService } from '../services/uploadImageServices';
import { adminService } from '../services/adminService';
import { FileConsultantRequest, FileConsultantResponse } from '../services/adminService';

interface AddFileToS3Props {
  label: string;
  onUploadComplete: (url: string) => void;
  url?: string | null;
  optional?: boolean;
  errorMessage?: string | null;
}

export interface FileUploaderRef {
  uploadFiles: () => Promise<string>;
  hasFiles: () => boolean;
}

const AddFileToS3 = forwardRef<FileUploaderRef, AddFileToS3Props>(({ label, onUploadComplete, url, optional = false, errorMessage }, ref) => {
  const [files, setFiles] = useState<File[]>([]);
  const [existingUrl, setExistingUrl] = useState<string | undefined>(url || undefined);

  const renderPreview = (file: File | string) => {
    if (typeof file === 'string') {
      // Si es una URL, mostrarla
      if (file.endsWith('.pdf')) {
        return <embed src={file} type="application/pdf" width="150px" height="150px" />;
      } else if (file.match(/\.(xlsx|xls)$/i)) {
        return (
          <div className="p-2 border rounded bg-gray-200" style={{ width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
            <div className="text-center">
              <i className="fas fa-file-excel text-3xl text-green-600 mb-2"></i>
              <p className="text-sm break-words">{file.split('/').pop()}</p>
            </div>
          </div>
        );
      } else {
        return <img src={file} alt="evidence-preview" style={{ width: '150px', height: '150px' }} />;
      }
    } else {
      // Si es un archivo, mostrarlo
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        return <img src={URL.createObjectURL(file)} alt={`preview-${file.name}`} style={{ width: '150px', height: '150px' }} />;
      } else if (fileType === 'application/pdf') {
        return <embed src={URL.createObjectURL(file)} type="application/pdf" width="150px" height="150px" />;
      } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileType === 'application/vnd.ms-excel') {
        return (
          <div className="p-2 border rounded bg-gray-200" style={{ width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
            <div className="flex flex-col text-center justify-center items-center">
              <i className="fas fa-file-excel text-3xl text-green-600 mb-2"></i>
              <p className="text-sm break-words">Archivo excel {file.name.split('/').pop()}</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="p-2 border rounded bg-gray-200" style={{ width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
            {file.name}
          </div>
        );
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setExistingUrl(undefined); // Limpiar la URL existente cuando se sube un nuevo archivo
    },
  });

  useImperativeHandle(ref, () => ({
    uploadFiles: async () => {
      if (files.length > 0) {
        const file = files[0];
        const request = new FileConsultantRequest(file.name);
        const response: FileConsultantResponse = await new Promise((resolve, reject) => {
          adminService.generateFileConsultantUrl(
            request,
            resolve,
            reject
          );
        });

        await new Promise<void>((resolve, reject) => {
          uploadImageService.uploadImage(
            { file, url: response.url },
            () => resolve(),
            reject
          );
        });

        onUploadComplete(response.final_url);
        return response.final_url;
      }
      return url || '';
    },
    hasFiles: () => files.length > 0
  }));

  return (
    <div className="grid gap-1 mb-4">
      <label className="text-m">
        {label} {!optional && <span className="text-red-500">*</span>}
      </label>
      <div className="bg-secundaryYellow hover:bg-primaryYellow font-semibold text-secondaryBrown p-5 text-center" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Suelta el archivo aquí...</p> : <p>Añadir Archivo</p>}
      </div>
      <div className="grid grid-cols-3 place-content-center place-items-center w-full">
        {existingUrl ? (
          <div className="p-2">
            {renderPreview(existingUrl)}
          </div>
        ) : (
          files.map((file, index) => (
            <div key={index} className="p-2">
              {renderPreview(file)}
            </div>
          ))
        )}
      </div>
      {errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
    </div>
  );
});

export default AddFileToS3;
