import React from 'react';

interface CircularProgressProps {
  percentage: number;
  text: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage, text }) => {
  const radius = 90;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const getColor = () => {
    if (percentage <= 60) {
      return '#F54C2F';
    } else if (percentage <= 84) {
      return '#F29100';
    }
    return '#077219';
  };

  return (
    <div className="grid text-center place-content-center relative justify-items-center group">
      <div className="relative w-[192px] h-[189px]">
        <svg width="192" height="192" className="transform -rotate-90">
          <circle
            cx="96"
            cy="96"
            r={radius}
            stroke="#e6e6e6"
            strokeWidth="12"
            fill="transparent"
          />
          <circle
            cx="96"
            cy="96"
            r={radius}
            stroke={getColor()}
            strokeWidth="12"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            className="transition-stroke-dashoffset duration-500 ease-out"
          />
        </svg>

        <span className="absolute inset-0 flex items-center justify-center text-secondaryBrown text-xxl font-semibold z-20">
          {percentage.toFixed()}
          <span className="text-lg align-top">%</span>
        </span>
        <div className="absolute mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-secondaryGray border-2 border-secondaryBrown text-black text-xs rounded p-2 z-30 w-64 left-1/2 transform -translate-x-1/2">
          <span className="text-red-500"><strong>ROJO</strong> </span>: Cumplimiento del SGSST entre 0 - 60% (No Aceptable). <br />
          <span className="text-yellow-500"><strong>AMARILLO</strong></span>: CUMPLIMIENTO SG-SST entre 61% - 84% (Estado Crítico). <br />
          <span className="text-green-500"><strong>VERDE</strong></span>: Cumplimiento SG-SST mayor al 85% (Estado Aceptable).
        </div>
      </div>

      <p className="text-lg font-semibold mt-2 text-primaryGray">{text}</p>
    </div>
  );
};

export default CircularProgress;
