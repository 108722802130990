// Modal.tsx
import React from 'react';

interface ModalCalendarProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const ModalCalendar: React.FC<ModalCalendarProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
            <div className="flex flex-col justify-center items-center bg-white shadow-lg w-auto mx-auto p-4 border-[1px] border-secondaryBrown rounded-lg">
                {children}
                <button onClick={onClose} className="mt-4 bg-primaryRed text-white text-m px-4 py-2 rounded-lg font-semibold ">
                    Cerrar
                </button>
            </div>
        </div>
    );
};

export default ModalCalendar;
