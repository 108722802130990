import React, { ReactNode } from 'react';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar'; // Asegúrate de importar Sidebar si es necesario
import { useAppSelector } from '../app/hooks';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const userType = useAppSelector((state) => state.user.userType);

  return (
    <div className="h-screen grid grid-cols-[180px_1fr] grid-rows-[auto_1fr] max-w-[1920px]">
      <aside className="fixed z-50 top-0 h-screen w-[180px]">
        <Sidebar />
      </aside>

      {userType !== 'admin' && (
        <header className="fixed z-10 left-[180px] mobile:left-0 right-0 m-0 b-0">
          <Header />
        </header>
      )}

      <main
        className={`p-4 ${userType !== 'admin' ? 'mt-[217px] tablet:mt-[307px] mobile:mt-[339px]' : 'mt-0'} overflow-auto h-[calc(100vh-203px)] col-start-2 row-start-2 mobile:col-start-1 mobile:col-span-2`}
      >
        {children}
      </main>
    </div>
  );
};

export default MainLayout;


