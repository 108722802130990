import React, { useEffect, useRef, useState } from 'react';
import HeaderSurveys from '../../components/HeaderSurveys';
import TextFieldString from '../../components/TextFieldString';
import Button from '../../components/Button';
import CancelButton from '../../components/CancelButton';
import SelectInput from '../../components/SelectInput';
import { ConsultantData } from '../../services/adminService';
import AddFileToS3, { FileUploaderRef } from '../../components/AddFileToS3';
import TextFieldNumber from '../../components/TextFieldNumber';

const licenceTypes = [
    "Técnico",
    "Tecnólogo",
    "Profesional",
    "Especialista"
];


interface RegisterAAndEditConsultantModalProps {
    open: boolean;
    onClose: () => void;
    onCreateOrUpdate: (formData: CreateAndEditConsultantFormData) => void;
    consultant?: ConsultantData;
    title: string;
}

export interface CreateAndEditConsultantFormData {
    first_name: string;
    last_name: string;
    id_card: string;
    phone: string;
    email: string;
    type_of_license: string;
    number_of_licence: string;
    place_of_issuance: string;
    date_of_issuance: string;
    url_license_document: string;
    url_course_document: string;
    url_cv: string;
    is_consultant_cuidamos: boolean;
    url_other_diplomas?: string;
}

const RegisterAAndEditConsultantModal: React.FC<RegisterAAndEditConsultantModalProps> = ({ open, onClose, onCreateOrUpdate, consultant, title }) => {
    const fileUploaderRef = useRef<Record<string, FileUploaderRef | null>>({
        license: null,
        course: null,
        cv: null,
        other_diplomas: null,
    });
    const [formData, setFormData] = useState<CreateAndEditConsultantFormData>({
        first_name: '',
        last_name: '',
        id_card: '',
        phone: '',
        email: '',
        type_of_license: '',
        number_of_licence: '',
        place_of_issuance: '',
        date_of_issuance: '',
        url_license_document: '',
        url_course_document: '',
        url_cv: '',
        is_consultant_cuidamos: true,
        url_other_diplomas: '',
    });

    const [errors, setErrors] = useState<Record<keyof CreateAndEditConsultantFormData, string | null>>({
        first_name: null,
        last_name: null,
        id_card: null,
        phone: null,
        email: null,
        type_of_license: null,
        number_of_licence: null,
        place_of_issuance: null,
        date_of_issuance: null,
        url_license_document: null,
        url_course_document: null,
        url_cv: null,
        is_consultant_cuidamos: null,
        url_other_diplomas: null,
    });
    useEffect(() => {
        console.log("Tipo de licencia:", formData.type_of_license);
    }, [formData.type_of_license])

    const initialFormData = {
        first_name: '',
        last_name: '',
        id_card: '',
        phone: '',
        email: '',
        type_of_license: '',
        number_of_licence: '',
        place_of_issuance: '',
        date_of_issuance: '',
        url_license_document: '',
        url_course_document: '',
        url_cv: '',
        is_consultant_cuidamos: true,
        url_other_diplomas: '',
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setErrors({
            first_name: null,
            last_name: null,
            id_card: null,
            phone: null,
            email: null,
            type_of_license: null,
            number_of_licence: null,
            place_of_issuance: null,
            date_of_issuance: null,
            url_license_document: null,
            url_course_document: null,
            url_cv: null,
            is_consultant_cuidamos: null,
            url_other_diplomas: null,
        });
        fileUploaderRef.current = {
            license: null,
            course: null,
            cv: null,
            other_diplomas: null,
        };
    };

    useEffect(() => {
        if (consultant) {
            setFormData(consultant);
        } else {
            setFormData(initialFormData);
        }
    }, [consultant, open]);

    const handleInputChange = (field: keyof CreateAndEditConsultantFormData, value: any) => {
        setFormData(prev => ({ ...prev, [field]: value }));
        setErrors(prev => ({ ...prev, [field]: null }));
    };

    const validateForm = (formData: CreateAndEditConsultantFormData): Partial<Record<keyof CreateAndEditConsultantFormData, string | null>> => {
        const validations: Partial<Record<keyof CreateAndEditConsultantFormData, string | null>> = {
            first_name: formData.first_name ? null : 'Este campo es requerido',
            last_name: formData.last_name ? null : 'Este campo es requerido',
            id_card: formData.id_card ? null : 'Este campo es requerido',
            phone: formData.phone ? null : 'Este campo es requerido',
            email: formData.email ? null : 'Este campo es requerido',
            type_of_license: formData.type_of_license ? null : 'Este campo es requerido',
            number_of_licence: formData.number_of_licence ? null : 'Este campo es requerido',
            place_of_issuance: formData.place_of_issuance ? null : 'Este campo es requerido',
            date_of_issuance: formData.date_of_issuance ? null : 'Este campo es requerido',
            url_license_document: formData.url_license_document || fileUploaderRef.current.license?.hasFiles() ? null : 'Este campo es requerido',
            url_course_document: formData.url_course_document || fileUploaderRef.current.course?.hasFiles() ? null : 'Este campo es requerido',
            url_cv: formData.url_cv || fileUploaderRef.current.cv?.hasFiles() ? null : 'Este campo es requerido',
            is_consultant_cuidamos: formData.is_consultant_cuidamos !== undefined ? null : 'Este campo es requerido',
        };

        return validations;
    };

    const handleCreateOrUpdate = async () => {
        try {
            const validationErrors = validateForm(formData);
            const fullErrors = { ...errors, ...validationErrors } as Record<keyof CreateAndEditConsultantFormData, string | null>;

            if (Object.values(validationErrors).some((error) => error !== null)) {
                setErrors(fullErrors);
                return;
            }

            const updatedUrls: Partial<CreateAndEditConsultantFormData> = {};

            if (fileUploaderRef.current.license?.hasFiles()) {
                updatedUrls.url_license_document = await fileUploaderRef.current.license.uploadFiles();
            }
            if (fileUploaderRef.current.course?.hasFiles()) {
                updatedUrls.url_course_document = await fileUploaderRef.current.course.uploadFiles();
            }
            if (fileUploaderRef.current.cv?.hasFiles()) {
                updatedUrls.url_cv = await fileUploaderRef.current.cv.uploadFiles();
            }
            if (fileUploaderRef.current.other_diplomas?.hasFiles()) {
                updatedUrls.url_other_diplomas = await fileUploaderRef.current.other_diplomas.uploadFiles();
            }

            const finalFormData = {
                ...formData,
                url_license_document: updatedUrls.url_license_document || formData.url_license_document,
                url_course_document: updatedUrls.url_course_document || formData.url_course_document,
                url_cv: updatedUrls.url_cv || formData.url_cv,
                url_other_diplomas: updatedUrls.url_other_diplomas || formData.url_other_diplomas || undefined
            };

            onCreateOrUpdate(finalFormData);
            resetForm();
            onClose();
        } catch (error) {
            console.error('Error durante la carga de archivos o validación:', error);
        }
    };

    const handleCloseModal = () => {
        resetForm();  // Limpiar el formulario al cerrar
        onClose();
    };

    const handleUploadComplete = (label: string, url: string) => {
        setFormData(prev => {
            const newFormData = { ...prev };
            switch (label) {
                case 'Documento de licencia':
                    newFormData.url_license_document = url;
                    break;
                case 'Documento de curso':
                    newFormData.url_course_document = url;
                    break;
                case 'Hoja de vida':
                    newFormData.url_cv = url;
                    break;
                case 'Otros diplomas':
                    newFormData.url_other_diplomas = url;
                    break;
            }
            return newFormData;
        });
    };



    return open ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
            <div className="bg-white rounded-lg shadow-lg min-h-[700px] w-[500px] border-2 border-secondaryBrown max-h-full overflow-y-auto mobile:max-h-screen">
                <HeaderSurveys title={`${title} consultor`} />
                <div className="grid text-start py-4 px-6">
                    <TextFieldString
                        label="Nombre"
                        value={formData.first_name}
                        onChange={e => handleInputChange('first_name', e.target.value)}
                        errorMessage={errors.first_name}
                    />
                    <TextFieldString
                        label="Apellido"
                        value={formData.last_name}
                        onChange={e => handleInputChange('last_name', e.target.value)}
                        errorMessage={errors.last_name}
                    />
                    <TextFieldNumber
                        label="Cédula"
                        value={formData.id_card ? parseInt(formData.id_card) : null}
                        onChange={e => handleInputChange('id_card', e.target.value)}
                        errorMessage={errors.id_card}
                    />
                    <TextFieldNumber
                        label="Celular"
                        value={formData.phone ? parseInt(formData.phone) : null}
                        onChange={e => handleInputChange('phone', e.target.value)}
                        errorMessage={errors.phone}
                    />
                    <TextFieldString
                        label="Email"
                        value={formData.email}
                        onChange={e => handleInputChange('email', e.target.value)}
                        errorMessage={errors.email}
                    />
                    <SelectInput
                        id="type-licence"
                        label="Tipo de Licencia"
                        value={formData.type_of_license}
                        options={licenceTypes.map((license) => ({ value: license, label: license }))}
                        onChange={(event) => handleInputChange('type_of_license', event.target.value)}
                        errorMessage={errors.type_of_license}
                    />
                    <TextFieldNumber
                        label="Número de Licencia"
                        value={formData.number_of_licence ? parseInt(formData.number_of_licence) : null}
                        onChange={e => handleInputChange('number_of_licence', e.target.value)}
                        errorMessage={errors.number_of_licence}
                    />
                    <TextFieldString
                        label="Lugar de Emisión"
                        value={formData.place_of_issuance}
                        onChange={e => handleInputChange('place_of_issuance', e.target.value)}
                        errorMessage={errors.place_of_issuance}
                    />
                    <TextFieldString
                        label="Fecha de Emisión"
                        type="date"
                        value={formData.date_of_issuance}
                        onChange={e => handleInputChange('date_of_issuance', e.target.value)}
                        errorMessage={errors.date_of_issuance}
                    />
                    <AddFileToS3
                        url={consultant?.url_cv}
                        label="Hoja de vida"
                        ref={(el) => fileUploaderRef.current.cv = el}
                        onUploadComplete={(url) => handleUploadComplete('Hoja de vida', url)}
                        errorMessage={errors.url_cv}
                    />
                    <AddFileToS3
                        url={consultant?.url_license_document}
                        label="Documento de licencia"
                        ref={(el) => fileUploaderRef.current.license = el}
                        onUploadComplete={(url) => handleUploadComplete('Documento de licencia', url)}
                        errorMessage={errors.url_license_document}
                    />
                    <AddFileToS3
                        url={consultant?.url_course_document}
                        label="Documento de curso 50 horas"
                        ref={(el) => fileUploaderRef.current.course = el}
                        onUploadComplete={(url) => handleUploadComplete('Documento de curso', url)}
                        errorMessage={errors.url_course_document}
                    />
                    <AddFileToS3
                        url={consultant?.url_other_diplomas}
                        label="Otros diplomas"
                        ref={(el) => fileUploaderRef.current.other_diplomas = el}
                        onUploadComplete={(url) => handleUploadComplete('Otros diplomas', url)}
                        optional={true}
                    />

                    <div className="flex justify-center gap-7 pb-4">
                        <Button text="Guardar Cambios" onClick={handleCreateOrUpdate} />
                        <CancelButton text="Cancelar" onClick={handleCloseModal} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default RegisterAAndEditConsultantModal;
