import React from 'react';
import CreateAbsenteeismModal from './absenteeism/CreateAbsenteeismModal';
import deleteIcon from '../assets/icons/delete-icon.svg'
import ConfirmationDeleteModal from './ConfirmationDeleteModal';

export interface AbsenceResponse {
  id: number;
  absense_type: string;
  description: string | null;
  start_date: string;
  end_date: string;
  evidence_url: string;
  employee_data: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export interface Column {
  id: keyof AbsenceResponse | 'employee_data' | 'edit' | 'delete';
  label: string;
}

interface TableProps {
  columns: Column[];
  rows: AbsenceResponse[];
  handleUpdate?: (formData: AbsenceResponse) => void;
  handleDeleteClick: (id: number) => void

}

const Table: React.FC<TableProps> = ({ columns, rows, handleUpdate, handleDeleteClick }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editModal, setEditModal] = React.useState<boolean>(false);
  const [absenteeismToDelete, setAbsenteismToDelete] = React.useState<number | null>(null);
  const [confirmationDeleteModal, setConfirmationDeleteModal] = React.useState<boolean>(false);

  const [selectedRow, setSelectedRow] = React.useState<AbsenceResponse | undefined>(undefined); // Estado para la fila seleccionada

  const handleChangePage = (newPage: number) => {
    if (newPage >= 0 && newPage < Math.ceil(rows.length / rowsPerPage)) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleEdit = (row: AbsenceResponse) => {
    console.log("Editando fila con ID:", row);
    setSelectedRow(row);
    setEditModal(true); // Abrir el modal de edición
  };

  const handleConfirmDelete = () => {
    if (absenteeismToDelete) {
      handleDeleteClick(absenteeismToDelete);
      setAbsenteismToDelete(null);
    }
    setConfirmationDeleteModal(false);
  };

  const handleDeleteClickWithConfirmation = (id: number) => {
    setAbsenteismToDelete(id);
    setConfirmationDeleteModal(true);
  };
  const handleCloseModal = () => {
    setAbsenteismToDelete(null);
    setConfirmationDeleteModal(false);
  };


  return (
    <>
      <div className="overflow-x-auto w-full h-[600px] ">
        <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
          <thead className='sticky top-0 bg-white'>
            <tr className="w-full">
              {columns.map((column) => (
                <th key={column.id} className='text-center px-2 py-4 text-m font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'>
                  <span >{column.label}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-secondaryBrown text-center bg-white divide-y divide-gray-200'>
            {rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {columns.map((column) => {
                    let value: string | number | React.ReactNode = '';

                    if (column.id === 'employee_data') {
                      const employeeData = row.employee_data;
                      value = `${employeeData.first_name} ${employeeData.last_name}`;
                    } else if (column.id === 'edit') {
                      value = (
                        <button
                          type="button"
                          onClick={() => handleEdit(row)}
                          className="text-secondaryOrange"
                        >
                          Editar
                        </button>
                      );
                    } else if (column.id === 'delete') {
                      value = (
                        <div>
                          <button
                            type="button"
                            onClick={() => handleDeleteClickWithConfirmation(row.id)}
                          >
                            <img src={deleteIcon} alt="delete icon" className="min-w-4 min-h-4 max-w-4 max-h-4" />
                          </button>
                        </div>
                      );
                    } else {
                      value = row[column.id];
                    }

                    return (
                      <td key={column.id} className="px-6 py-3 text-sm">
                        {column.id === 'evidence_url' ? (
                          <a
                            className='text-secondaryOrange '
                            href={value as string}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver Evidencia
                          </a>
                        ) : (
                          typeof value === 'string' || typeof value === 'number'
                            ? value.toString()
                            : value
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center py-4">No hay datos disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center py-2">
          <button
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0}
            className="p-2 border border-gray-300 rounded bg-gray-200"
          >
            Anterior
          </button>
          <div>
            <label className='mr-2 text-primaryBrown'>Número de filas</label>
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage} className="p-2 border border-gray-300 rounded">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
          <button
            onClick={() => handleChangePage(page + 1)}
            disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
            className="p-2 border border-gray-300 rounded bg-gray-200"
          >
            Siguiente
          </button>
        </div>
      </div>
      <CreateAbsenteeismModal
        open={editModal}
        onClose={() => {


          setEditModal(false);
        }}
        onUpdate={handleUpdate}
        createAbsenteeismFormData={selectedRow}
      />
      <ConfirmationDeleteModal
        open={confirmationDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        message="¿Estás seguro de que quieres eliminar este ausentismo?"
      />
    </>

  );
};

export default Table;
