import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initializeState: () => any = () => {
    const accessToken = localStorage.getItem('access_token');
    const userType = localStorage.getItem('user_type');
    const companyId = localStorage.getItem('company_id');
    const companyName = localStorage.getItem('company_name');

    if (accessToken) {
        return {
            isLoggedIn: accessToken !== null && accessToken !== undefined,
            userType: userType || null,
            companyId: companyId || null,
            companyName: companyName || null,
        };
    }
    return {
        isLoggedIn: false,
        companyId: null,
        companyName: null,
    };
};

const userSlice = createSlice({
    name: 'user',
    initialState: initializeState,
    reducers: {
        setLoggedIn(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setUserType(state, action: PayloadAction<string>) {
            state.userType = action.payload;
        },
        setCompany(state, action: PayloadAction<{ companyId: string; companyName: string }>) {
            state.companyId = action.payload.companyId;
            state.companyName = action.payload.companyName;

            localStorage.setItem('company_id', action.payload.companyId);
            localStorage.setItem('company_name', action.payload.companyName);
        },
    }
});

export const { setLoggedIn, setUserType, setCompany } = userSlice.actions;
export default userSlice.reducer;
