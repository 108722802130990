import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, onClick, disabled = false }) => (
  <button
    className={`bg-primaryOrange text-m rounded-lg px-2 h-[36px] min-w-[170px] text-white font-semibold hover:bg-secondaryOrange 
      ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
  >
    {text}
  </button>
);

export default Button;
