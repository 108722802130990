import { Box, Button, Modal } from "@mui/material";
import { DataWorkEnv } from "../services/uploadImageServices";

interface EvidenceModalProps {
  open: boolean;
  onClose: () => void;
  evidence: DataWorkEnv[];
}

const EvidenceModal: React.FC<EvidenceModalProps> = ({ open, onClose, evidence }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        maxWidth: '1000px',
        minHeight: '350px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
      }}
      className="overflow-auto"
    >
      <div className="text-center mb-8 font-bold text-xl">Evidencias del puesto de trabajo</div>
      <div className="flex flex-wrap justify-center gap-4">
        {evidence.map((item, index) => (
          <div className="flex_col justify-center text-center">

            <div key={index} className="w-48 h-48 overflow-hidden rounded-lg shadow-md">
              <img
                src={item.url}
                alt={`evidence-${index}`}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-red-300 hover:text-red-400">
              <a href={item.url} target="blank">Ver más</a>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center text-center gap-4 mt-10">
        <Button onClick={onClose} variant="contained" color="error" className="ml-2">
          Cerrar
        </Button>
      </div>
    </Box>
  </Modal>
);

export default EvidenceModal;
