import React, { useState, useEffect } from 'react';
import notificationsIcon from '../assets/icons/notifications-icon.svg';
import { companyService, NotificationData, NotificationPlanData } from '../services/companyServices';
import { useAppSelector } from '../app/hooks';

type Props = {};

const Notifications = (props: Props) => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [expiringActivities, setExpiringActivities] = useState<NotificationPlanData[]>([]);
  const [notificationMessage, setNotificationMessage] = useState<NotificationData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    companyService.getNotifications(
      (response) => {
        setExpiringActivities(response.plans);
        setNotificationMessage(response.notifications)
      },
      () => {
        setErrorMessage('No se pudo obtener notificaciones.');
      }
    );
  }, [companyId, companyName]);

  const handleClickNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  return (
    <div className="relative z-40">
      <button
        className="relative text-secondaryPink"
        onClick={handleClickNotifications}
      >
        <img src={notificationsIcon} alt="Notifications" className="w-14 h-14" />
        {errorMessage && (
          <span className="text-red-500 text-sm">{errorMessage}</span>
        )}
        {expiringActivities.length + notificationMessage.length > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 rounded-full">
            {expiringActivities.length + notificationMessage.length}
          </span>
        )}
      </button>
      {notificationsOpen && (
        <div className="absolute  z-10 right-0 mt-4 w-96 bg-white border-2 max-h-96 overflow-x-auto  text-secondaryBrown border-secondaryBrown rounded-lg shadow-lg p-4">
          <div className="text-xl text-center font-semibold text-primaryOrange bg-white">
            Notificaciones
          </div>
          <ol className="mt-10">
            {notificationMessage.length > 0 ? (
              notificationMessage.map((notification) => (
                <li
                  key={notification.id}
                  className="flex text-start justify-around mb-4 w-full text-primaryRed"
                >
                  <div>{notification.message}</div>
                </li>
              ))
            ) : (
              <div></div>
            )}

            {expiringActivities.length > 0 ? (
              expiringActivities.map((plan, index) => (
                <li
                  key={plan.id}
                  className="flex text-start justify-between px-2 mb-2 gap-3"
                >
                  <div className="">
                    {index + 1}. {plan.activity}
                  </div>
                  <div className="text-secondaryOrange font-semibold">
                    {plan.status}
                  </div>
                </li>
              ))
            ) : (
              <li className='mt-4'>No hay actividades por vencer</li>
            )}
          </ol>
        </div>
      )}
    </div>
  );

};

export default Notifications;
