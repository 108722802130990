import React from 'react';

export interface Option {
  value: string; // El valor de la opción
  label: string; // El texto que se mostrará
  disabled?: boolean; // Opcional, para desactivar opciones
}

interface SelectInputProps {
  id: string;
  label: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  title?: string;
  errorMessage?: string | null; // Propiedad opcional para el mensaje de error
}

const SelectInput: React.FC<SelectInputProps> = ({ id, label, value, onChange, options, title, errorMessage }) => {


  return (
    <div className="w-full flex flex-col mb-4">
      <label htmlFor={id} className="mb-1 text-m">
        {label}
      </label>
      <select
        id={id}
        title={title}
        value={value} // El valor seleccionado
        onChange={onChange} // Función que maneja el cambio
        className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
      >
        <option value="" disabled>
          Selecciona
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value} // El valor de la opción
            disabled={option.disabled} // Si la opción está desactivada
          >
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage && (
        <span className="text-red-600 text-sm mt-1">{errorMessage}</span>
      )}
    </div>
  );
};

export default SelectInput;
