import React, { useState, useEffect } from 'react';
import SelectInput from './SelectInput';
import TextFieldDate from './TextFieldDate';
import TextFieldString from './TextFieldString';
import Button from './Button';
import { standarsItemsTeam3 } from '../utils/standarItems';
import CancelButton from './CancelButton';
import HeaderSurveys from './HeaderSurveys';
import SelectInputNumber from './SelectInputNumber';
import MultiSelectInput from './SelectInputNumberMultiple';
import { customizedItems } from '../utils/customizedItems';
import { EmployeesListData, employeesService } from '../services/employeesService';

export interface PlanEdit {
  id: string;
  item: string;
  activity: string;
  manager: number;
  participants: number[];
  startDate: Date | null;
  endDate: Date | null;
  evidences: string;
  state: string;
  isCustomizedItem: boolean;
}

type OptionType = {
  label: string;
  value: number;
};

interface PlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  plan: PlanEdit;
  onSave: (updatedPlan: PlanEdit) => void;
}

const EditPlanModal: React.FC<PlanModalProps> = ({ isOpen, onClose, plan, onSave }) => {
  const [item, setItem] = useState(plan.item);
  const [activity, setActivity] = useState(plan.activity);
  const [manager, setManager] = useState(plan.manager);
  const [participants, setParticipants] = useState(plan.participants);
  const [startDate, setStartDate] = useState<Date | null>(plan.startDate);
  const [endDate, setEndDate] = useState<Date | null>(plan.endDate);
  const [state, setState] = useState(plan.state);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        console.log('Datos recibidos:', response);

        const options = response.results.map((employee: EmployeesListData) => ({
          label: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        }));

        setEmployeeOptions(options);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (plan) {
      setItem(plan.item);
      setActivity(plan.activity);
      setManager(plan.manager);
      setParticipants(plan.participants);
      setState(plan.state);
      setStartDate(plan.startDate);
      setEndDate(plan.endDate);
    }
  }, [plan]);

  const handleSave = () => {
    if (!item || !activity || !manager || participants.length === 0 || !startDate || !endDate) {
      setErrorMessage("Por favor, completa todos los campos.");
      return;
    }

    if (startDate > endDate) {
      setErrorMessage("La fecha de inicio no puede ser posterior a la fecha de fin.");
      return;
    }

    const updatedPlan: PlanEdit = {
      ...plan,
      item,
      activity,
      manager,
      participants,
      startDate: startDate ? (startDate) : null,
      endDate: endDate ? (endDate) : null,
      evidences: plan.evidences,
      state,
    };

    onSave(updatedPlan);
    onClose();
  };

  if (!isOpen) return null;

  const mapToOptions = (items: { propName: string; text: string }[]) => {
    return items.map(item => ({
      value: item.propName,
      label: item.text,
    }));
  };

  const options = plan.isCustomizedItem ? mapToOptions(customizedItems) : mapToOptions(standarsItemsTeam3);

  const handleParticipantsChange = (selectedValues: number[]) => {
    setParticipants(selectedValues);
  };

  return (
    <div className="fixed zin inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg min-h-[700px] w-[500px]">
        <HeaderSurveys title="Editar planes de mejora" />
        <div className="flex flex-col justify-center items-center py-4 px-6">
          <SelectInput
            id="item"
            label="Item"
            value={item}
            onChange={(e) => setItem(e.target.value)}
            options={options}
          />
          <TextFieldString
            label="Actividad"
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
          />
          <MultiSelectInput
            id='participants-id'
            options={employeeOptions}
            label="Participantes"
            values={participants}
            onChange={handleParticipantsChange}
          />
          <SelectInputNumber
            id='manager-id'
            options={employeeOptions}
            label="Responsable"
            value={manager}
            onChange={(newValue) => setManager(newValue)}
          />
          <TextFieldDate
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => setStartDate(e.target.valueAsDate)}
          />
          <TextFieldDate
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => setEndDate(e.target.valueAsDate)}
          />
          <span className="col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm">
            {errorMessage && <div>{errorMessage}</div>}
          </span>
        </div>
        <div className='flex justify-center gap-7 pb-4'>
          <Button
            text="Guardar Cambios"
            onClick={handleSave}
          />
          <CancelButton
            text="Cancelar"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPlanModal;
