import React, { useState } from 'react';
import evidenceIcon from '../assets/icons/evidence-icon.svg';
import { PlanEdit } from './EditImprovementPlan';
import deleteIcon from '../assets/icons/delete-icon.svg'
import ConfirmationDeleteModal from './ConfirmationDeleteModal';
import { FaInfoCircle } from 'react-icons/fa';
import ListParticipantsModal from './ListParticipantsModal';

interface Column {
  id: string;
  label: string;
  format?: (value: any) => string;
}

interface Row {
  [key: string]: any;
}

interface CustomTableProps {
  title: string;
  columns: Column[];
  rows: Row[];
  page: number;
  rowsPerPage: number;
  handleOpenModal: (item: string, id: string) => void;
  handleOpenModalShowEvidence: (itemName: string, activity: string) => void;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleEdit: (plan: PlanEdit) => void;
  handleDeleteClick: (id: string) => void
}

const parseDateString = (dateString: string): Date => {
  const months = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  // Verificar que dateString no sea undefined, null o vacío
  if (!dateString) {
    return new Date(); // Valor predeterminado si el string no es válido
  }

  const [day, month, year] = dateString.split(' de ');

  // Verificar que day, month y year no sean undefined
  if (!day || !month || !year) {
    console.error('Formato de fecha inválido:', dateString);
    return new Date(); // Valor predeterminado si el string no es válido
  }

  const monthIndex = months.indexOf(month.toLowerCase());

  // Verificar que el mes sea válido
  if (monthIndex < 0) {
    console.error('Mes inválido:', month);
    return new Date(); // Valor predeterminado si el mes no es válido
  }

  // Crear la fecha con el formato correcto
  return new Date(`${year}-${monthIndex + 1}-${day}`);
};

const ImprovementsPlansTable: React.FC<CustomTableProps> = ({
  title,
  columns,
  rows,
  page,
  rowsPerPage,
  handleOpenModal,
  handleOpenModalShowEvidence,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEdit,
  handleDeleteClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const [isParticipantsModalOpen, setParticipantsModalOpen] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);

  const handleEditClick = (row: Row) => {
    console.log('Start Date Original:', row.startDate);
    console.log('End Date Original:', row.endDate);
    const plan: PlanEdit = {
      id: row.id,
      item: row.itemName,
      activity: row.activity,
      manager: row.managerId,
      participants: row.participantsIds || [],
      startDate: row.startDate ? parseDateString(row.startDate) : null,
      endDate: row.endDate ? parseDateString(row.endDate) : null,
      evidences: row.evidences || '',
      state: row.state || '',
      isCustomizedItem: row.isCustomizedItem,
    };

    handleEdit(plan);
  };

  const handleDeleteClickWithConfirmation = (id: string) => {
    setItemToDelete(id);
    setIsModalOpen(true);
  };


  const handleConfirmDelete = () => {
    if (itemToDelete) {
      handleDeleteClick(itemToDelete);
      setItemToDelete(null);
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setItemToDelete(null);
    setIsModalOpen(false);
  };

  const handleOpenParticipantsModal = (participants: string[]) => {
    setSelectedParticipants(participants);
    setParticipantsModalOpen(true);
  };

  const handleCloseParticipantsModal = () => {
    setParticipantsModalOpen(false);
  };



  return (
    <div className="my-14">
      <div className='flex items-center mb-2'>
        <div className="text-xl text-start font-semibold text-secondaryOrange">{title}</div>
        <div className="ml-2 relative flex items-center group">
          <FaInfoCircle className="text-secondaryOrange cursor-pointer" />
          <div className="absolute top-full left-0 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-secondaryGray text-black border-2 border-secondaryBrown text-xs rounded p-2 z-50 w-64">
            {title === 'items estándares'
              ? 'Ítems relacionados con estándares legales a cumplir'
              : title === 'items extras'
                ? 'Ítems relacionados con batería de riesgo psicosocial, programas de bienestar, estudio de puestos de trabajo, entre otros.'
                : null}
          </div>
        </div>
      </div>
      <div className='overflow-x-auto  max-h-[600px] ] '>
        <table className=" w-full   bg-white border-separate border border-spacing-x-2 border-transparent">
          <thead className='sticky top-0 bg-white '>
            <tr className="">
              {columns.map((column) => (
                <th
                  key={column.id}
                  className='text-center px-2 py-4 text-wrap text-mm font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg z-10'
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-secondaryBrown text-center bg-white divide-y divide-gray-200">
            {rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  {columns.map((column) => {
                    if (column.id === 'participants') {
                      const participantes = row["participants"] || '';
                      console.log(participantes);

                      return (
                        <td key={column.id} className="px-2 py-4 text-m">
                          {participantes && participantes.split(',').filter((p: string) => p.trim() !== '').length > 2 ? (
                            <button
                              onClick={() => handleOpenParticipantsModal(participantes.split(',').map((p: string) => p.trim()))}
                              className="text-primaryOrange"
                            >
                              Ver participantes ({participantes.split(',').filter((p: string) => p.trim() !== '').length})
                            </button>
                          ) : (
                            participantes.split(',').map((p: string) => p.trim()).join(', ')
                          )}
                        </td>
                      );
                    }
                    return (
                      <td key={column.id} className="px-2 py-4 text-m">
                        {column.id === 'planDeMejora' ? (
                          <button
                            className={`text-primaryOrange ${!row.planDeMejora ? 'text-secondaryOrange cursor-not-allowed' : ''}`}
                            onClick={() => handleOpenModal(row.item + ' ' + row.estandar, 'Plan de Mejora')}
                            disabled={!row.planDeMejora}
                          >
                            Plan de mejora
                          </button>
                        ) : column.id === 'evidencias' ? (
                          <button
                            type='button'
                            onClick={() => handleOpenModal(row.item, row.evidences)}
                          >
                            <img src={evidenceIcon} alt="evidence icon" className="mr-2" />
                          </button>

                        ) : column.id === 'showEvidence' ? (

                          <button
                            onClick={() => handleOpenModalShowEvidence(row.itemName, row.activity)}
                            className='text-primaryOrange'
                          >
                            Ver evidencias
                          </button>

                        ) : column.id === 'edit' ? (
                          <button
                            type="button"
                            onClick={() => handleEditClick(row)}
                            className="text-secondaryOrange"
                          >
                            Editar
                          </button>
                        ) : column.id === 'delete' ? (
                          <button
                            type="button"
                            onClick={() => handleDeleteClickWithConfirmation(row.id)}
                          >
                            <img src={deleteIcon} alt="delete icon" className="min-w-4 min-h-4 max-w-4 max-h-4" />
                          </button>
                        ) : (
                          <span>{row[column.id]}</span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center py-4">
                  No hay {title} disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center py-2">
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
        >
          Anterior
        </button>
        <div>
          <label className="mr-2 text-primaryBrown">Número de filas</label>
          <select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            className="border border-gray-300 rounded-md px-2 py-1"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
          </select>
        </div>
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
        >
          Siguiente
        </button>
      </div>
      <ConfirmationDeleteModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        message="¿Estás seguro de que quieres eliminar esta actividad?"
      />
      <ListParticipantsModal
        open={isParticipantsModalOpen}
        onClose={handleCloseParticipantsModal}
        participants={selectedParticipants}
      />
    </div>
  );
};

export default ImprovementsPlansTable;
