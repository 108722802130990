import Button from "./Button";

const ListParticipantsModal: React.FC<{ open: boolean, onClose: () => void, participants: string[] }> = ({ open, onClose, participants }) => {
    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-md w-[400px] max-h-[800px] flex flex-col">
                <h2 className="text-lg text-secondaryBrown font-semibold mb-6">Lista de Participantes</h2>
                <div className="overflow-y-auto flex flex-grow max-h-[500px] mb-6 items-start text-start">
                    <ul className="list-disc pl-5">
                        {participants.map((participant, index) => (
                            <li key={index} className="text-sm">{participant}</li>
                        ))}
                    </ul>
                </div>
                <div className="mt-auto">
                    <Button
                        onClick={onClose}
                        text="Cerrar"
                    />
                </div>
            </div>
        </div>

    );
};

export default ListParticipantsModal