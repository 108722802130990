import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import SuccessModal from "./SuccessModal";
import { employeesService } from "../services/employeesService";

interface AddExcelProps {
}

const AddExcel = (props: AddExcelProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const excelFiles = acceptedFiles.filter(file =>
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel'
        );
        setFiles(excelFiles);
        console.log(excelFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls']
        }
    });

    const uploadExcelPromise = (file: File) => {
        return new Promise(async (resolve, reject) => {
            try {
                await employeesService.uploadExcelFile((file), // Ajusta según tu implementación de servicio
                    response => {
                        console.log('Upload success:', response);
                        resolve(response);
                    },
                    error => {
                        setErrorMessage(error)
                    }
                );
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleClick = async (event: React.FormEvent) => {
        setLoading(true);
        setErrorMessage([]);

        try {
            for (const file of files) {
                await uploadExcelPromise(file);
            }
            setSuccessMessage('Archivo Excel subido exitosamente.');
            setShowModal(true);
        } catch (err) {

        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className="flex justify-center text-center gap-5 items-center">
                <div>
                    <div
                        className="flex justify-center items-center font-semibold bg-primaryOrange hover:bg-secondaryOrange min-w-[100px] px-2 h-[36px] text-center text-white rounded-md cursor-pointer"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? <p>Suelta el archivo aquí</p> : <p>Subir archivo</p>}
                    </div>

                </div>

                <div className="flex justify-center items-center font-semibold bg-primaryGreen hover:bg-secondaryGreen text-white min-w-[100px] h-[36px] rounded-lg text-center">
                    <button onClick={handleClick}>Enviar</button>
                </div>
            </div>
            <div className="grid  place-content-center place-items-center text-center mt-4 text-secondaryBrown">
                {files.map((file, index) => (
                    <div key={index}>
                        <p>{file.name}</p>
                    </div>
                ))}
            </div>
            {loading && <p className="mt-4 text-center">Cargando...</p>}
            {errorMessage && errorMessage.length > 0 && (
                <div className="mt-4 ">
                    <ul className="text-sm text-primaryRed list-disc list-inside ">
                        {errorMessage.map((errorMessage, index) => (
                            <li key={index} className="mt-2">{errorMessage}</li>
                        ))}
                    </ul>
                </div>
            )}
            {showModal && (
                <SuccessModal
                    message={successMessage}
                    buttonText="Continuar"
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default AddExcel;
