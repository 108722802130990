import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface LoginResponse {
  access: string;
  email: string;
  refresh: string;
  user_type: string;
}

export class LoginCredentials {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

type OnSuccess = (response: LoginResponse) => void;

type OnError = (error: any) => void;

const login = (credentials: LoginCredentials, onSuccess: OnSuccess, onError: OnError): void => {
  axios.post<LoginResponse>(`${API_URL}/api/auth/login`, credentials)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error))
};

type OnSuccessLogout = (response: any) => void;

const logout = (onSuccess: OnSuccessLogout, onError: OnError): void => {
  axios.post<LoginResponse>(`${API_URL}/api/auth/logout`, { refresh: localStorage.getItem("refresh") }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export const authService = {
  login,
  logout
};