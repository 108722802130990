import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface InspectionResponse {
    success: boolean;
    message: string;
    data: any;
}

export class InspectionData {
    clean_and_orderly_conditions: boolean;
    clean_and_orderly_conditions_notes: string;
    equipment_layout_safety: boolean;
    equipment_layout_safety_notes: string;
    sufficient_space: boolean;
    sufficient_space_notes: string;
    adequate_evacuation_routes: boolean;
    adequate_evacuation_routes_notes: string;
    hazard_warning_signage: boolean;
    hazard_warning_signage_notes: string;
    dangerous_zones_isolated: boolean;
    dangerous_zones_isolated_notes: string;
    first_aid_equipment: boolean;
    first_aid_equipment_notes: string;
    electrical_installations_condition: boolean;
    electrical_installations_condition_notes: string;
    machine_moving_parts_guarded: boolean;
    machine_moving_parts_guarded_notes: string;
    appropriate_tools_maintenance: boolean;
    appropriate_tools_maintenance_notes: string;
    msds_available_in_spanish: boolean;
    msds_available_in_spanish_notes: string;
    chemicals_properly_labelled: boolean;
    chemicals_properly_labelled_notes: string;
    risk_prevention_training: boolean;
    risk_prevention_training_notes: string;
    personal_protection_equipment_available: boolean;
    personal_protection_equipment_available_notes: string;
    ppe_delivery_records: boolean;
    ppe_delivery_records_notes: string;
    emergency_plan_written: boolean;
    emergency_plan_written_notes: string;
    safe_procedure_compliance: boolean;
    safe_procedure_compliance_notes: string;
    hazardous_conditions_reported: boolean;
    hazardous_conditions_reported_notes: string;
    incidents_and_accidents_reported: boolean;
    incidents_and_accidents_reported_notes: string;

    constructor(data: { [key: string]: boolean | string | number }) {
        Object.keys(data).forEach(key => {
            if (key in this) {
                (this as any)[key] = data[key];
            }
        });
    }
}

type OnSuccess = (response: InspectionResponse) => void;
type OnError = (error: any) => void;

export const submitInspection = async (inspectionData: InspectionData, onSuccess: OnSuccess, onError: OnError) => {
    try {
        const companyId = localStorage.getItem('company_id') ?? '';
        const response = await axios.post<InspectionResponse>(`${API_URL}/companies/auth/initial-inspection`, inspectionData,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                    'Content-Type': 'application/json',
                    'X-Company-Id': companyId
                },
            });
        onSuccess(response.data);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            onError(error.response?.data || "Unknown error");
        } else {
            onError("An unexpected error occurred");
        }
    }
};
