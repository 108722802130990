import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface UserRegisterResponse {
  token: string;
  user: any;
}

export class NewUserData {
  email: string | undefined;
  password: string;
  user_type: string;
  token?: string

  constructor(
    email: string,
    password: string,
    user_type: string,
    token?: string

  ) {
    this.email = email;
    this.password = password;
    this.user_type = user_type;
    this.token = token;
  }
}

type OnSuccess = (response: UserRegisterResponse) => void;

type OnError = (error: any) => void;

const createNewUser = (newUserData: NewUserData, onSuccess: OnSuccess, onError: OnError): void => {
  const accessToken = localStorage.getItem("access_token");
  const headers: any = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  axios.post<UserRegisterResponse>(`${API_URL}/api/auth/register`, newUserData, { headers })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export const newUserService = {
  createNewUser,
};