/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HealthConditionsData, ServiceSurveys } from '../../services/employeeSurveysService';
import SuccessModal from '../../components/SuccessModal';
import HeaderSurveys from '../../components/HeaderSurveys';
import { mainDivClassName } from '../../utils/MainDivClassName';
import TextFieldString from '../../components/TextFieldString';
import SelectInput from '../../components/SelectInput';
import TextFieldNumber from '../../components/TextFieldNumber';
import Button from '../../components/Button';

const options = [
  { value: "SI", label: "Sí" },
  { value: "NO", label: "No" }
];

const FormHealthConditions = () => {
  const [equipmentOperating, setEquipmentOperating] = useState<string>('');
  const [diseaseOrTreatment, setDiseaseOrTreatment] = useState<string>('NO');
  const [diseaseOrTreatmentExplain, setDiseaseOrTreatmentExplain] = useState<string>('NO APLICA');
  const [symptomsDescription, setSymptomsDescription] = useState<string>('NO APLICA');
  const [evolutionTime, setEvolutionTime] = useState<number | null>(0);
  const [workRelatedSymptoms, setWorkRelatedSymptoms] = useState<string>('NO APLICA');
  const [symptomsOutsideWork, setSymptomsOutsideWork] = useState<string>('NO APLICA');
  const [symptomsConsultation, setSymptomsConsultation] = useState<string>('NO APLICA');
  const [whyConsultation, setWhyConsultation] = useState<string>('NO APLICA');
  const [incapacity, setIncapacity] = useState<string>('NO APLICA');
  const [registrationIncapacity, setRegistrationIncapacity] = useState<string>("NO APLICA");
  const [daysIncapacity, setDaysIncapacity] = useState<number | null>(0)
  const [hobbies, setHobbies] = useState<string>('');
  const [timeHobbies, setTimeHobbies] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { token } = useParams<{ token?: string }>();

  const handleChangeSymptomsConsultation = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const value = event.target.value;
    setSymptomsConsultation(value);
  };

  const handleChangeDiseaseOrTreatment = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const value = event.target.value;
    setDiseaseOrTreatment(value);
  };

  const handleChangeIncapacity = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const value = event.target.value;
    setIncapacity(value);
  };


  const handleClick = () => {
    setLoading(true);
    let symptomsConsultationBool: boolean
    let incapacityBool: boolean

    if (symptomsConsultation === "SI") {
      symptomsConsultationBool = true
    } else {
      symptomsConsultationBool = false
    }

    if (incapacity === "SI") {
      incapacityBool = true
    } else {
      incapacityBool = false
    }
    if (!timeHobbies) {
      setErrorMessage('Todos los campos son obligatorios.');
      return
    }

    ServiceSurveys.registerHealthConditions(
      new HealthConditionsData(equipmentOperating, diseaseOrTreatmentExplain, symptomsDescription, evolutionTime, workRelatedSymptoms, symptomsOutsideWork, symptomsConsultationBool, whyConsultation, incapacityBool, registrationIncapacity, daysIncapacity, hobbies, timeHobbies, token
      ),
      response => {
        setLoading(false)
        setShowModal(true)
      },
      err => {
        setErrorMessage('Error en el envio de Datos.');
        setLoading(false)
      },

    );
    setLoading(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <SuccessModal
          message="Encuesta enviada con éxito. ¡Gracias por tomarte el tiempo para completar la encuesta!. Ya puedes cerrar la pestaña."
          buttonText=""
          onClose={handleCloseModal}
        />
      )}
      <div className={"flex justify-center  h-auto text-secondaryBrown " + (mainDivClassName)} >
        <div className='w-full m-3 h-auto border-2 rounded-md border-secondaryBrown bg-white desktop:w-3/6 desktopL:w-3/6 desktop:my-8 desktopL:my-8 md:my-8'>
          <HeaderSurveys title="Condiciones de salud" />
          <div className="grid md:grid-cols-2 p-4 gap-2 md:gap-x-32 place-items-end  desktop:gap-x-8 desktopL:gap-x-8 "
          >
            <TextFieldString
              label='¿ Qué equipos Opera?'
              value={equipmentOperating}
              title='Ingrese que equipos opera'
              onChange={(e) => setEquipmentOperating(e.target.value)}
            />
            <SelectInput
              id='disease-or-treatment'
              label='¿Actualmente presenta alguna enfermedad o esta en tratamiento médico?'
              value={diseaseOrTreatment}
              onChange={handleChangeDiseaseOrTreatment}
              options={options}
            />

            {diseaseOrTreatment === "SI" &&
              <>
                <TextFieldString
                  label='Explique'
                  value={diseaseOrTreatmentExplain}
                  title='Ingrese en que enfermedad o tratamiento esta actualmente'
                  onChange={(e) => setDiseaseOrTreatmentExplain(e.target.value)}
                />
                <TextFieldString
                  label='Describa que síntoma(s) físico(s) o emocional(es) percibe'
                  value={symptomsDescription}
                  title='Ingrese la descripción de los síntomas'
                  onChange={(e) => setSymptomsDescription(e.target.value)}
                />
                <TextFieldNumber
                  label='Tiempo de evolución (dias)'
                  value={evolutionTime}
                  title='Ingrese los dias de evolución'
                  onChange={(e) => setEvolutionTime(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
                />
                <TextFieldString
                  label='¿Considera que tiene alguna relación estos síntomas con su trabajo? Explique'
                  value={workRelatedSymptoms}
                  title='Ingrese la explicación de la relación'
                  onChange={(e) => setWorkRelatedSymptoms(e.target.value)}
                />
                <TextFieldString
                  label='¿Considera que tiene alguna relación éste síntoma con sus actividades fuera del trabajo? Explique'
                  value={symptomsOutsideWork}
                  title='Ingrese la explicación de la relación'
                  onChange={(e) => setSymptomsOutsideWork(e.target.value)}
                />
                <SelectInput
                  id='symptoms-consultation'
                  label='¿Ha consultado a su servicio de salud por estos síntomas?'
                  value={symptomsConsultation}
                  onChange={handleChangeSymptomsConsultation}
                  options={options}
                />
                <TextFieldString
                  label='¿Porqué ha consultado el servicio de salud?'
                  value={whyConsultation}
                  title='Ingrese el porque de la consulta'
                  onChange={(e) => setWhyConsultation(e.target.value)}
                />
                <SelectInput
                  id='incapacity'
                  label='¿Durante el último año ha sido incapacitado por esta causa?'
                  value={incapacity}
                  onChange={handleChangeIncapacity}
                  options={options}
                />
                <TextFieldString
                  label='Registre por que lo han incapacitado'
                  value={registrationIncapacity}
                  title='Ingrese porque lo han incapacitado'
                  onChange={(e) => setRegistrationIncapacity(e.target.value)}
                />
                <TextFieldNumber
                  label='¿Cuantos días de incapacidad médica?'
                  value={daysIncapacity}
                  title='Ingrese los dias de incapacitación'
                  onChange={(e) => setDaysIncapacity(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
                />
              </>
            }
            <TextFieldString
              label='¿Cual (es) hobbies tiene o practica algún deporte?'
              value={hobbies}
              title='Ingrese los hobbies'
              onChange={(e) => setHobbies(e.target.value)}
            />
            <TextFieldNumber
              label='¿Qué tiempo le dedica en total? (Horas a la semana)'
              value={timeHobbies}
              title='Ingrese el tiempo que le dedica a los hobbies'
              onChange={(e) => setTimeHobbies(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
            />

          </div>
          <span className='col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm'>
            {errorMessage && <div className='flex col-span-2 justify-center text-center'>{errorMessage}</div>}
          </span>
          <div className="text-center items-center my-3 ">
            <Button
              disabled={loading}
              onClick={handleClick}
              text='Enviar formulario'
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default FormHealthConditions
