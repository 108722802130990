import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

interface TextFieldNumberProps {
  label: string;
  value: number | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  errorMessage?: string | null;
}

const TextFieldNumber: React.FC<TextFieldNumberProps> = ({ label, value, onChange, title, errorMessage }) => (
  <div className="flex flex-col mb-4 w-full">
    <div className='flex justify-start items-center text-center'>
      <label className="mb-1 text-m">{label}</label>
      {title && (
        <div className="ml-2 relative flex items-center justify-center text-start group">
          <FaInfoCircle className="text-secondaryOrange cursor-pointer" />
          <div className="absolute bottom-full left-0 mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-secondaryGray text-black border-2 border-secondaryBrown text-xs rounded p-2 z-50 w-64">
            {title}
          </div>
        </div>
      )}
    </div>
    <input
      type="number"
      value={value !== null ? value.toString() : ''}
      onChange={onChange}
      className="py-2 px-3 border text-m border-gray-300 rounded bg-gray-200 text-sm min-w-[10rem] max-w-[12rem"
    />
    {errorMessage && (
      <span className="text-red-600 text-sm mt-1">{errorMessage}</span>
    )}
  </div>
);

export default TextFieldNumber;
