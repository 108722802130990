import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { ImprovementPlansData } from '../services/autoevaluationService';
import { formatDate } from './improvementPlan/ImprovementPlanDetailsModal';
import EvidencePlansModal from './improvementPlan/EvidencePlansModal';
import { EvidenceImprovementPlanDataResponse, GetEvidenceImprovementPlansByEmployee, uploadImageService } from '../services/uploadImageServices';
import { UploadEvidenceModal } from './improvementPlan/UploadEvidence';
import { EvidenceDetails } from './TableItems';
import CancelButton from './CancelButton';
import Button from './Button';

interface ImprovementPlansModalProps {
  open: boolean;
  onClose: () => void;
  improvementPlans: ImprovementPlansData[];
  employeeId?: number;
}

const ImprovementPlansPerEmployeeModal: React.FC<ImprovementPlansModalProps> = ({ open, onClose, improvementPlans, employeeId }) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [showEvidenceModalOpen, setShowEvidenceModalOpen] = useState(false);
  const [modalUploadEvidence, setModalUploadEvidence] = useState(false);
  const [evidenceDetails, setEvidenceDetails] = React.useState<EvidenceDetails[] | null>(null);
  const [activity, setActivity] = React.useState<string | undefined>(undefined);
  const [errorMessageShowEvidence, setErrorMessageShowEvidence] = React.useState<string>('');

  const handleShowEvidence = (plan: ImprovementPlansData) => {
    if (employeeId === null) return;
    if (plan.customized_item_name) {
      setSelectedItem(plan.customized_item_name);

      uploadImageService.getEvidenceImprovementPlans(
        new GetEvidenceImprovementPlansByEmployee(plan.customized_item_name, employeeId),
        (response: EvidenceImprovementPlanDataResponse[]) => {
          setEvidenceDetails(response);
          setActivity(plan.activity)
          setShowEvidenceModalOpen(true);
        },
        (error) => {
          console.log('Error al obtener los datos:', error);
          setErrorMessageShowEvidence('Error al obtener la evidencia.')
        },
      );
    } else if (plan.item_name) {
      uploadImageService.getEvidenceImprovementPlans(
        new GetEvidenceImprovementPlansByEmployee(plan.item_name, employeeId),
        (response: EvidenceImprovementPlanDataResponse[]) => {
          setEvidenceDetails(response);
          setActivity(plan.activity)
          setShowEvidenceModalOpen(true);
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
          setErrorMessageShowEvidence('Error al obtener la evidencia.')
        },
      );
    }
  };

  const handleAddEvidence = (plan: ImprovementPlansData) => {
    setSelectedItem(plan.item_name || plan.customized_item_name || '');
    setModalUploadEvidence(true);
  };

  const handleCloseModalUploadEvidence = () => {
    setModalUploadEvidence(false);
    setSelectedItem("");

  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '900px',
            minHeight: '350px',
            maxHeight: '600px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            zIndex: 1000,
          }}
        >
          <div className="text-xl text-start font-semibold text-secondaryOrange mb-2 ml-2">Planes de mejora</div>
          <div className='overflow-x-auto  max-h-[400px]'>
            <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
              <thead className='sticky top-0 bg-white z-10'>
                <tr className="w-full">
                  <th
                    key='Activity'
                    className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
                  >
                    Actividad
                  </th>
                  <th
                    key='starDate'
                    className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
                  >
                    Fecha de inicio
                  </th>
                  <th
                    key='endDate'
                    className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
                  >
                    Fecha que finaliza
                  </th>
                  <th
                    key='showEvidence'
                    className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
                  >
                    Ver evidencia
                  </th>
                  <th
                    key='addEvidence'
                    className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
                  >
                    añadir evidencia
                  </th>
                </tr>
              </thead>
              <tbody className='text-secondaryBrown text-center bg-white divide-y divide-gray-200'>
                {improvementPlans.length > 0 ? improvementPlans.map((plan, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className='px-6 py-3 text-sm'>{plan.activity}</td>
                    <td className='px-6 py-3 text-sm'>{formatDate(plan.start_date)}</td>
                    <td className='px-6 py-3 text-sm'>{formatDate(plan.end_date)}</td>
                    <td className='px-6 py-3 text-sm'>
                      <div className='grid text-center'>
                        <button
                          className='text-primaryOrange '
                          onClick={() => handleShowEvidence(plan)}>
                          Ver evidencia
                        </button>
                        <div>
                          <span className='text-sm text-red-500 text-center'>{errorMessageShowEvidence}</span>
                        </div>
                      </div>
                    </td>
                    <td className='px-6 py-3 text-sm'>
                      <Button
                        onClick={() => handleAddEvidence(plan)}
                        text="Añadir evidencia"
                      />

                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} align="center" className='px-6 py-3 text-sm'>No hay planes de mejora</td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>

          <div className="flex justify-center text-center gap-4 mt-10">
            <CancelButton
              onClick={onClose}
              text="Cerrar"
            />
          </div>
        </Box>
      </Modal>
      <UploadEvidenceModal
        title='plan de mejora por empleado'
        propName={selectedItem}
        show={modalUploadEvidence}
        onClose={handleCloseModalUploadEvidence}
        sx={{ zIndex: 1400 }}
        employeeId={employeeId}
      />
      <EvidencePlansModal open={showEvidenceModalOpen} onClose={() => setShowEvidenceModalOpen(false)} details={evidenceDetails} activity={activity} />
    </>
  );
};

export default ImprovementPlansPerEmployeeModal;
