import { useEffect, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import { adminService, ConsultantDataResponse } from '../../services/adminService';
import Button from '../../components/Button';
import SuccessModal from '../../components/SuccessModal';
import RegisterAAndEditConsultantModal from './RegisterAndEditConsultantModal';

const columns = [
    { id: 'name', label: 'Nombre' },
    { id: 'email', label: 'Correo' },
    { id: 'id_card', label: 'Cédula' },
    { id: 'phone', label: 'Celular' },
    { id: 'is_consultant_cuidaamos', label: 'Consultor cuidamos' },
    { id: 'status', label: 'Estado' },
    { id: 'actions', label: 'Cambiar estado' },
    { id: 'edit', label: 'Editar' },
];

const ListConsultants = () => {
    const [consultants, setConsultants] = useState<ConsultantDataResponse[]>([]);
    const [consultant, setConsultant] = useState<ConsultantDataResponse | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);
    const [idCard, setIdCard] = useState<string>()
    const [firtsName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [showSuccess, setShowSuccess] = useState(false);
    const [next, setNext] = useState<string | undefined>(undefined);
    const [previous, setPrevious] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        fetchDataConsultants()
    }, []);

    const fetchDataConsultants = (url?: string) => {
        const filters = {
            first_name: firtsName || undefined,
            last_name: lastName || undefined,
            id_card: idCard || undefined,
        };

        const cleanFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== undefined)
        );

        adminService.consultantsData(
            (response) => {
                setConsultants(response.results);
                setNext(response.next);
                setPrevious(response.previous);
                cleanData();
            },
            (error) => {
                setErrorMessage("Error al obtener los consultores");
            },
            Object.keys(cleanFilters).length > 0 ? cleanFilters : undefined
        );
    };

    const cleanData = () => {
        setConsultant(undefined);
        setErrorMessage("");
        cleanFilters();
    }

    const handleEditConsultant = (consultant: ConsultantDataResponse) => {
        console.log("editar consultor :");
        consultant.email = consultant.user_email;
        setConsultant(consultant);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowSuccess(false);
        setSuccessMessage("");
        cleanData();
    };

    const handleCloseRegisterConsultantModal = () => {
        setShowModal(false)
        cleanData();
    }

    const handleRegisterOrUpdateConsultantModal = (formData: any) => {
        if (formData.id) {
            adminService.updateConsultant(formData,
                (response) => {
                    setShowSuccess(true);
                    setSuccessMessage("Consultor actualizado correctamente");
                    setConsultant(undefined);
                    setShowModal(false);
                    cleanFilters();
                    fetchDataConsultants();
                },
                (error) => {
                    setErrorMessage("Error al actualizar el consultor");
                }
            );
        } else {
            adminService.createConsultant(formData,
                (response) => {
                    setSuccessMessage("Consultor creado correctamente");
                    setShowSuccess(true);
                    setConsultant(undefined);
                    setShowModal(false);
                    cleanFilters();
                    fetchDataConsultants();
                },
                (error) => {
                    setErrorMessage("Error al crear el consultor");
                }
            );
        }
    };

    const cleanFilters = () => {
        setFirstName('');
        setLastName('');
        setIdCard('');
        setErrorMessage('');
    };

    return (
        <MainLayout>
            <div className="grid text-center items-center text-secondaryBrown p-4">
                <h1 className="text-4xl text-start font-bold mb-10 text-primaryOrange">
                    Consultores
                </h1>
                <span className='text-m text-primaryRed text-center my-4'>{errorMessage}</span>
                <div className='flex justify-between items-center w-full'>
                    <div className='flex justify-start items-center my-4'>
                        <Button
                            text='Crear consultor'
                            onClick={() => {
                                setShowModal(true)
                            }}
                        />
                    </div>
                    <div className='flex justify-end items-end gap-4 my-4'>
                        <div className="flex flex-col justify-center  w-full">
                            <label className="mb-1 text-m text-start">Nombre</label>
                            <input
                                type='text'
                                value={firtsName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                            />
                        </div>
                        <div className="flex flex-col justify-center  w-full">
                            <label className="mb-1 text-m text-start">Apellido</label>
                            <input
                                type='text'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="mb-1 text-m text-start">Cédula</label>
                            <input
                                type='number'
                                value={idCard}
                                onChange={(e) => setIdCard(e.target.value)}
                                className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 max-h-[35px] text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
                            />
                        </div>
                        <Button
                            onClick={fetchDataConsultants}
                            text='Filtrar'
                        />
                    </div>
                </div>

                <div className="text-sm overflow-x-auto w-full max-h-[600px]">
                    <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
                        <thead className="sticky top-0 bg-white">
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        key={column.id}
                                        className="text-center z-10 px-2 py-4 text-m font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg"
                                    >
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {consultants.length > 0 ? (
                                consultants.map((consultant) => (
                                    <tr key={consultant.id} className="hover:bg-gray-100">
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            {consultant.first_name} {consultant.last_name}
                                        </td>

                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {consultant.user_email}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {consultant.id_card}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {consultant.phone}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {consultant.is_consultant_cuidamos ? 'Si' : 'No'}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {consultant.status}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            <Button
                                                onClick={() => {
                                                    const updatedConsultant = {
                                                        ...consultant,
                                                        status: consultant.status === 'Activo' ? 'Inactivo' : 'Activo',
                                                        email: consultant.user_email
                                                    };
                                                    handleRegisterOrUpdateConsultantModal(updatedConsultant);
                                                }}
                                                text={consultant.status === 'Activo' ? 'Inactivar' : 'Activar'}
                                            />
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            <Button
                                                onClick={() => handleEditConsultant(consultant)}
                                                text="Editar"
                                            />

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className="px-6 py-4 text-center text-gray-500"
                                    >
                                        No hay consultores
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between gap-5 mt-4">
                    <button
                        className="p-2 border border-gray-300 rounded bg-gray-200"
                        color="primary"
                        disabled={!previous}
                        onClick={() => fetchDataConsultants(previous)}
                    >
                        Anterior
                    </button>
                    <button
                        className="p-2 border border-gray-300 rounded bg-gray-200"
                        color="primary"
                        disabled={!next}
                        onClick={() => fetchDataConsultants(next)}
                    >
                        Siguiente
                    </button>
                </div>
            </div>

            {showSuccess && (
                <SuccessModal
                    message={successMessage}
                    buttonText="Continuar"
                    onClose={closeModal}
                />
            )}
            <RegisterAAndEditConsultantModal consultant={consultant} title='Crear' open={showModal} onClose={handleCloseRegisterConsultantModal} onCreateOrUpdate={handleRegisterOrUpdateConsultantModal} />
        </MainLayout>
    );
};

export default ListConsultants;
